import React, { Component } from "react";
import moment from "moment";
import {
  Button,
  Form,
  Input,
  Layout,
  Row,
  Col,
  message,
  Upload
} from "antd";
import Sidebar from "../Components/Sidebar";
import "antd/dist/antd.css";
import styles from "./CSS Modules/Company.model.css";

import {
  FileImageOutlined,
} from "@ant-design/icons";
import Axios from "../axios";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import "rc-color-picker/assets/index.css";
const { Dragger } = Upload;
const { Content } = Layout;


class AddUpdateCrops extends Component {
  intl = this.props.intl;
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      id: null,
      image_preview: "",
      image: null,
      region: null,
      name: null,
      sort: null,
      national_country: null,
      foreign_country: null,
      register_date: null,
      volume: null,
      weight: null,
      period_harvest: null,
      date_harvest: null,
      weight_harvest: null,
      save_date: null,
      eksport: null,
      main_property: null,
      additional: null,
      regionList: [
        "uz-fa",
        "uz-tk",
        "uz-an",
        "uz-ng",
        "uz-ji",
        "uz-si",
        "uz-ta",
        "uz-bu",
        "uz-kh",
        "uz-qr",
        "uz-nw",
        "uz-sa",
        "uz-qa",
        "uz-su",
      ]
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    if (this.state.type === "Update")
      Axios.get("/crops/" + this.props.computedMatch.params.id)
        .then((result) => {
          let state = result.data;
          this.setState({
            id: state.id,
            region: state.region,
            name: state.name,
            sort: state.sort,
            national_country: state.national_country,
            register_date: state.register_date,
            volume: state.volume,
            weight: state.weight,
            period_harvest: state.period_harvest,
            weight_harvest: state.weight_harvest,
            save_date: state.save_date,
            main_property: state.main_property,
            additional: state.additional,
            eksport: state.eksport,
            image_preview: state.image,
            image: state.image,
            isAdded: false,
          });
        })
        .catch(() => {
          this.setState({
            notExist: true,
          });
        });
  };

  onAddClick = (event) => {
    event.target.disabled = true;
    let params = this.createForm();
    // for (var pair of params.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }
    Axios.post("/admin/crops", params)
      .then(() => {
        message.error(this.intl.formatMessage({ id: "crop.add.success" }));
        this.setState({
          isAdded: true,
        });
      })
      .catch((err) => {
        if (err.response.status === 406)
          message.error(
            this.intl.formatMessage({ id: "crop.add.error.already-exist" })
          );
        else
          message.error(this.intl.formatMessage({ id: "crop.add.error" }));

        event.target.disabled = false;
      });

  };

  onUpdateClick = () => {
    let params = this.createFormUpdate();
    Axios.post("/admin/update-crops/" + this.state.id, params)
      .then((res) => {
        this.setState({
          isAdded: true,
        });
        message.success(
          this.intl.formatMessage({ id: "crop.update.success" })
        );
      })
      .catch(() => {
        message.error(
          this.intl.formatMessage({ id: "crop.update.error" })
        );
      });
  };


  createForm = () => {
    let params = new FormData();
    params.append("region", this.state.region);
    params.append("name", this.state.name);
    params.append("sort", this.state.sort);
    params.append("national_country", this.state.national_country);
    params.append("register_date", this.state.register_date);
    params.append("volume", this.state.volume);
    params.append("weight", this.state.weight);
    params.append("period_harvest", this.state.period_harvest);
    params.append("weight_harvest", this.state.weight_harvest);
    params.append("save_date", this.state.save_date);
    params.append("main_property", this.state.main_property);
    params.append("additional", this.state.additional);
    params.append("eksport", this.state.eksport);
    params.append("file", this.state.image);
    return params;
  };

  createFormUpdate = () => {
    let params = new FormData();
    params.append("region", this.state.region);
    params.append("name", this.state.name);
    params.append("sort", this.state.sort);
    params.append("national_country", this.state.national_country);
    params.append("register_date", this.state.register_date);
    params.append("volume", this.state.volume);
    params.append("weight", this.state.weight);
    params.append("period_harvest", this.state.period_harvest);
    params.append("weight_harvest", this.state.weight_harvest);
    params.append("save_date", this.state.save_date);
    params.append("main_property", this.state.main_property);
    params.append("additional", this.state.additional);
    params.append("eksport", this.state.eksport);
    params.append("image", this.state.image);
    return params;
  };
  onDateChange = (event) => {
    this.setState({
      register_date: event.target.value,
    });
  };

  onDeleteClick = (event, index) => {
    let prices = this.state.prices;
    prices.splice(index, 1);
    this.setState({
      prices: prices,
    });
  };

  onProductClick = () => {
    this.setState({
      prices: [...this.state.prices, {
        local_price: "",
        local_price_discount: "",
        price_dollar: "",
        date: ""
      }],
    });
  };

  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };


  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };
  onRegionChange = (event) => {
    this.setState({
      region: event.target.value,
    });
  };

  onSortChange = (event) => {
    this.setState({
      sort: event.target.value,
    });
  };

  onNatiolcountryChange = (event) => {
    this.setState({
      national_country: event.target.value,
    });
  };

  onCountryChange = (event) => {
    this.setState({
      foreign_country: event.target.value,
    });
  };

  onVolumeChange = (event) => {
    this.setState({
      volume: event.target.value,
    });
  };

  onWeightChange = (event) => {
    this.setState({
      weight: event.target.value,
    });
  };

  onPeriodChange = (event) => {
    this.setState({
      period_harvest: event.target.value,
    });
  };

  onSaveDateChange = (event) => {
    this.setState({
      save_date: event.target.value,
    });
  };

  onDateharvestChange = (event) => {
    this.setState({
      date_harvest: event.target.value,
    });
  };

  onWeightharvestChange = (event) => {
    this.setState({
      weight_harvest: event.target.value,
    });
  };

  onMainChange = (event) => {
    this.setState({
      main_property: event.target.value,
    });
  };
  onAddtionalChange = (event) => {
    this.setState({
      additional: event.target.value,
    });
  };
  onEksportChange = (event) => {
    this.setState({
      eksport: event.target.value,
    });
  };

  onChange(date, dateString) {
    this.setState({
      open: date._d,
    });
  }

  handleRegion = (value) => {
    this.setState({ region: value });
  };

  closeHandler = (colors) => {
    // console.log(colors);
  };

  handleChange = (value) => {
    this.setState({
      product_sell_type: value
    })
  };
  getDateFormat = date => {
    var d = moment(date);
    return date && d.isValid() ? d : '';
  };
  render() {


    return (
      <Layout>
        <Sidebar selected={"crops"} />
        <Layout
          className={styles["site-layout"]}
          style={{ marginLeft: "28vw" }}
        >
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
              width: "1000px",
            }}
          >
            <Form
              name="form_name"
              fields={[
                {
                  name: ["region"],
                  value: this.state.region,
                },
                {
                  name: ["name"],
                  value: this.state.name,
                },
                {
                  name: ["sort"],
                  value: this.state.sort,
                },
                {
                  name: ["national_country"],
                  value: this.state.national_country,
                },
                {
                  name: ["register_date"],
                  value: this.state.register_date,
                },
                {
                  name: ["volume"],
                  value: this.state.volume,
                },

                {
                  name: ["weight"],
                  value: this.state.weight,
                },

                {
                  name: ["period_harvest"],
                  value: this.state.period_harvest,
                },

                {
                  name: ["weight_harvest"],
                  value: this.state.weight_harvest,
                },
                {
                  name: ["save_date"],
                  value: this.state.save_date,
                },
                {
                  name: ["main_property"],
                  value: this.state.main_property,
                },

                {
                  name: ["additional"],
                  value: this.state.additional,
                },
                {
                  name: ["eksport"],
                  value: this.state.eksport,
                },
              ]}
            >
              <Form.Item>
                {this.state.type === "Add" ? (
                  <h1 style={{ textAlign: "center" }}>
                    {this.intl.formatMessage({ id: "crops.add" })}
                  </h1>
                ) : (
                  <h1 style={{ textAlign: "center" }}>
                    {this.intl.formatMessage({ id: "crops.update" })}
                  </h1>
                )}
              </Form.Item>
              <Row style={{ marginBottom: "20px" }}>
                <Col span={12}>
                  <Form.Item
                    name="region"
                    rules={[
                      {
                        required: true,
                      },
                    ]} >
                    <Input
                      onChange={this.onRegionChange}
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.name' })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                      },
                    ]} >
                    <Input
                      onChange={this.onNameChange}
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.name' })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="sort"
                    rules={[
                      {
                        required: true,
                      },
                    ]} >
                    <Input
                      onChange={this.onSortChange}
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.sort' })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="national_country"
                    rules={[{ required: true }]} >
                    <Input
                      onChange={this.onNatiolcountryChange}
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.national_country' })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="register_date"
                    rules={[
                      {
                        required: true,
                      },
                    ]} >
                    <Input
                      onChange={this.onDateChange}
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.name' })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="volume"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      onChange={this.onVolumeChange}
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.volume' })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="weight"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      onChange={this.onWeightChange}
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.weight' })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="period_harvest"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.period_harvest' })}
                      onChange={this.onPeriodChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="weight_harvest"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.weight_harvest' })}
                      onChange={this.onWeightharvestChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="save_date"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.save_date' })}
                      onChange={this.onSaveDateChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="main_property"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.main_property' })}
                      onChange={this.onMainChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="additional"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >

                    <Input onChange={this.onAddtionalChange}
                      placeholder={this.intl.formatMessage({ id: 'crop.placeholder.additional' })}
                    />
                  </Form.Item>

                </Col>
                <Form.Item
                  name="eksport"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >

                  <Input onChange={this.onEksportChange}
                    placeholder={'Eksport'}
                  />
                </Form.Item>
              </Row>
              <Col span={12} offset={6}>
                {this.state.image_preview === "" ? (
                  <h3
                    style={{
                      padding: "10px",
                      textAlign: "center",
                      border: "1px solid black",
                      width: "100%",
                    }}
                  >
                    {this.intl.formatMessage({
                      id: "article.placeholder.preview-image",
                    })}
                  </h3>
                ) : (
                  <img src={this.state.image_preview} width="100%" alt="" />
                )}
              </Col>
              <Form.Item name="preview_image">
                <Dragger
                  {...{
                    name: "image",
                    multiple: false,
                    maxCount: 1,
                    showUploadList: false,
                  }}
                  beforeUpload={(file) => {
                    if (
                      file.type !== "image/png" ||
                      file.type !== "image/jpeg" ||
                      file.type !== "image/jpg"
                    ) {
                      this.setState({
                        image_preview: window.URL.createObjectURL(file),
                        image: file,
                      });
                      // console.log(this.state.image)
                      // return false;
                    } else {
                      message.error(
                        file.name +
                        " " +
                        this.intl.formatMessage({
                          id: "article.file-error.image",
                        })
                      );
                      return false;
                    }
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <FileImageOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {this.intl.formatMessage({
                      id: "article.drag.preview-image",
                    })}
                  </p>
                </Dragger>
              </Form.Item>
              <Form.Item>
                {this.state.type === "Add" ? (
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={this.onAddClick}
                    className={styles["login-form-button"]}
                  >
                    {this.intl.formatMessage({ id: "company.add" })}
                  </Button>
                ) : (
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={this.onUpdateClick}
                    className={styles["login-form-button"]}
                  >
                    {this.intl.formatMessage({ id: "company.update" })}
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Content>
        </Layout>
        {this.state.isAdded ? <Redirect to="/crops" /> : null}
        {this.state.notExist && <Redirect to="/404" />}
      </Layout>
    );
  }
}

export default injectIntl(AddUpdateCrops);
