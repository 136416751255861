import React, { Component } from 'react';
import { Button, Col, Form, Input, Layout, message,  Select, Upload } from "antd";
import Sidebar from "../Components/Sidebar";
import styles from "./CSS Modules/Tags.module.css";
import { FileImageOutlined} from '@ant-design/icons'
import JoditEditor from "jodit-react";
import Axios from "../axios";
import "jodit/build/jodit.min.css";
import { injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";

const { TextArea } = Input;
const { Content } = Layout
const { Option } = Select
const { Dragger } = Upload;

class AddUpdateArticle extends Component {
    intl = this.props.intl

    sections = ['News', 'Article', 'Analysis', 'Event', 'Pomegranate',  'Legal', 'Fruitsandvegetables', 'Projectnews']

    constructor(props) {
        super(props);
        let choose_sections = []
        switch (this.intl.locale) {
            case 'uz':
                choose_sections = ['Yangiliklar', 'Artikl', 'Tahlil', 'Tadbir', 'Anorchilar Assotsiatsiya yangiliklar', 'Huquqiy savodhonlik', 'Meva va  sabzavot  yangiliklari', 'Proyekt yangiliklari']
                break;
            case 'ru':
                choose_sections = ['Новости', 'Статья', 'Анализ', 'Мероприятие', 'Новости Гранатовой Ассоциации', 'юридическая грамотность', 'Новости фруктов и овощей','новости проекта']
                break;
            case 'en':
                choose_sections = ['News', 'Article', 'Analysis', 'Event', 'Pomegranate Association news', 'Legal literacy', 'Fruit and Vegetable news','Project news']
                break;
            default:
                choose_sections = ['News', 'Article', 'Analysis', 'Event', 'Pomegranate Association news', 'Legal  literacy', 'Fruit and Vegetable news','Project news']
                break;
        }

        this.state = {
            id: null,
            type: this.props.type,
            html: '',
            data: {},
            author: '',
            title: '',
            subtitle: '',
            section: undefined,
            sections: choose_sections,
            image_preview: '',
            image_preview_file: null,
            banner_image: '',
            banner_image_file: null,
            tags: [],
            chosenTags: [],
            notExist: false,
            isAdded: false
        };
    }

    componentDidMount() {
        this.getTags()
        this.getData()
    }

    getData = () => {
        if (this.state.type === 'Update') {
            Axios.get('/admin/article/' + this.props.computedMatch.params.id)
                .then((res) => {
                    this.setState({
                        html: res.data.html,
                        title: res.data.title,
                        subtitle: res.data.subtitle,
                        author: res.data.author,
                        chosenTags: res.data.tags,
                        section: res.data.section,
                        image_preview: res.data.preview_image,
                        banner_image: res.data.banner_image,
                    })
                })
                .catch(() => {
                    this.setState({
                        notExist: true
                    })
                })
        }
    }

    getTags = () => {
        Axios.get('/tags')
            .then((res) => {
                let tags = res.data.map((item) => item.name)
                this.setState({
                    tags: tags
                })
            })
    }

    onTitleChange = (event) => {
        this.setState({
            title: event.target.value
        })
    }

    onSubTitleChange = (event) => {
        this.setState({
            subtitle: event.target.value
        })
    }

    onTagsChange = (value) => {
        this.setState({
            chosenTags: value
        })
    }

    onAuthorChange = (event) => {
        this.setState({
            author: event.target.value
        })
    }

    onSectionChange = (value) => {
        this.setState({
            section: value
        })
    }

    formData = () => {
        let formData = new FormData()
        formData.append('html', this.state.html)
        formData.append('subtitle', this.state.subtitle)
        formData.append('title', this.state.title)
        formData.append('author', this.state.author)
        formData.append('section', this.state.section)
        formData.append('tags', JSON.stringify(this.state.chosenTags))
        formData.append('preview_image', this.state.image_preview_file);
        formData.append('banner_image', this.state.banner_image_file);
        return formData
    }

    onAddClick = (event) => {
        event.target.disabled = true;
        let formData = this.formData();
        Axios.post('/admin/article', formData)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'article.add.success' }))
                this.setState({
                    isAdded: true
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'article.add.error' }))
                event.target.disabled = false
            })
    }

    onUpdateClick = () => {
        let formData = this.formData();
        Axios.post('/admin/update-article/' + this.props.computedMatch.params.id, formData)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'article.update.success' }))
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'article.update.error' }))
            })
    }

    render() {
        return (
            <Layout>
                <Sidebar selected={'articles'} />
                <Layout className={styles["site-layout"]} style={{ marginLeft: '13vw' }}>
                    <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
                        <div style={{ padding: "50px" }}>
                            <Form
                                style={{ marginBottom: '50px' }}
                                fields={[
                                    {
                                        name: ["title"],
                                        value: this.state.title
                                    },
                                    {
                                        name: ["author"],
                                        value: this.state.author
                                    },
                                    {
                                        name: ["tags"],
                                        value: this.state.chosenTags
                                    },
                                    {
                                        name: ["section"],
                                        value: this.state.section
                                    },
                                    {
                                        name: ["subtitle"],
                                        value: this.state.subtitle
                                    },
                                ]}
                            >
                                <Form.Item>
                                    {
                                        this.state.type === 'Add' ?
                                            <h1 style={{ textAlign: "center", fontSize: '30px' }}>
                                                {this.intl.formatMessage({ id: 'article.add' })}
                                            </h1> :
                                            <h1 style={{ textAlign: "center", fontSize: '30px' }}>
                                                {this.intl.formatMessage({ id: 'article.update' })}
                                            </h1>
                                    }
                                </Form.Item>
                                <Col span={8}>
                                    <h3>{this.intl.formatMessage({ id: 'article.input.title' })}</h3>
                                    <Form.Item
                                        name="title"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'article.warning.title' }),
                                            },
                                        ]}
                                    >
                                        <Input value={this.state.title} onChange={this.onTitleChange}
                                            placeholder={this.intl.formatMessage({ id: 'article.placeholder.title' })}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <h3>{this.intl.formatMessage({ id: 'article.input.author' })}</h3>
                                    <Form.Item
                                        name="author"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'article.warning.author' }),
                                            },
                                        ]}
                                    >
                                        <Input value={this.state.author} onChange={this.onAuthorChange}
                                            placeholder={this.intl.formatMessage({ id: 'article.placeholder.author' })}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <h1>{this.intl.formatMessage({ id: 'article.input.tags' })}</h1>
                                    <Form.Item
                                        name="tags"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'article.warning.tags' }),
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder={this.intl.formatMessage({ id: 'article.placeholder.tags' })}
                                            onChange={this.onTagsChange}
                                            value={this.state.chosenTags}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {this.state.tags.map((tag) => {
                                                return <Option key={tag} value={tag}>{tag}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <h1>{this.intl.formatMessage({ id: 'article.input.section' })}</h1>
                                    <Form.Item
                                        name="section"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'article.warning.section' }),
                                            },
                                        ]}
                                    >
                                        <Select
                                            mode="single"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder={this.intl.formatMessage({ id: 'article.placeholder.section' })}
                                            onChange={this.onSectionChange}
                                            value={this.state.section}
                                        >
                                            {
                                                this.state.sections.map((section, index) => {
                                                    return <Option key='news' value={this.sections[index]}>{section}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <h3>{this.intl.formatMessage({ id: 'article.input.subtitle' })}</h3>
                                    <Form.Item
                                        name="subtitle"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'article.warning.subtitle' }),
                                            },
                                        ]}
                                    >
                                        <TextArea value={this.state.subtitle} onChange={this.onSubTitleChange}
                                            placeholder={this.intl.formatMessage({ id: 'article.placeholder.subtitle' })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8} style={{ marginTop: '20px' }}>
                                    {
                                        this.state.image_preview === '' ?
                                            <h3 style={{
                                                padding: '10px',
                                                textAlign: 'center',
                                                border: '1px solid black',
                                                width: '100%'
                                            }}>
                                                {this.intl.formatMessage({ id: 'article.placeholder.preview-image' })}
                                            </h3>
                                            :
                                            <img src={this.state.image_preview} width='100%' alt="" />
                                    }
                                </Col>

                                <Col span={8} style={{ marginTop: '20px' }}>
                                    <Form.Item name="preview_image">
                                        <Dragger {...{
                                            name: 'image',
                                            multiple: false,
                                            maxCount: 1,
                                            showUploadList: false
                                        }} beforeUpload={(file) => {
                                            if (file.type !== 'image/png' || file.type !== 'image/jpeg' || file.type !== 'image/jpg') {
                                                this.setState({
                                                    image_preview: window.URL.createObjectURL(file),
                                                    image_preview_file: file
                                                })
                                                return false
                                            } else {
                                                message.error(file.name + ' ' + this.intl.formatMessage({ id: 'article.file-error.image' }));
                                                return false
                                            }
                                        }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <FileImageOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                {this.intl.formatMessage({ id: 'article.drag.preview-image' })}
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Col>
                                <Col span={8} style={{ marginTop: '20px' }}>
                                    {
                                        this.state.banner_image === '' ?
                                            <h3 style={{
                                                padding: '10px',
                                                textAlign: 'center',
                                                border: '1px solid black',
                                                width: '100%'
                                            }}>
                                                {this.intl.formatMessage({ id: 'article.placeholder.preview-image' })}
                                            </h3>
                                            :
                                            <img src={this.state.banner_image} width='100%' alt="" />
                                    }
                                </Col>
                                <Col span={8} style={{ marginTop: '20px' }}>
                                    <Form.Item name="banner_image">
                                        <Dragger {...{
                                            name: 'banner_image',
                                            multiple: false,
                                            maxCount: 1,
                                            showUploadList: false
                                        }} beforeUpload={(file) => {
                                            if (file.type !== 'image/png' || file.type !== 'image/jpeg' || file.type !== 'image/jpg') {
                                                this.setState({
                                                    banner_image: window.URL.createObjectURL(file),
                                                    banner_image_file: file
                                                })
                                                return false
                                            } else {
                                                message.error(file.name + ' ' + this.intl.formatMessage({ id: 'article.file-error.image' }));
                                                return false
                                            }
                                        }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <FileImageOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                {this.intl.formatMessage({ id: 'article.drag.preview-image' })}
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Col>
                                <JoditEditor
                                    value={this.state.html}
                                    onChange={content => {
                                        this.setState({ html: content })
                                    }
                                    }
                                />
                                {
                                    this.state.type === 'Add' ?
                                        <Button style={{ marginTop: '50px' }} block type="primary" htmlType="submit"
                                            onClick={this.onAddClick}
                                            className={styles['login-form-button']}>
                                            {this.intl.formatMessage({ id: 'article.add' })}
                                        </Button>
                                        :
                                        <Button style={{ marginTop: '50px' }} block type="primary" htmlType="submit"
                                            onClick={this.onUpdateClick}
                                            className={styles['login-form-button']}>
                                            {this.intl.formatMessage({ id: 'article.update' })}
                                        </Button>
                                }
                            </Form>
                        </div>
                    </Content>
                    {
                        this.state.isAdded ? <Redirect to='/articles' /> : null
                    }
                </Layout>
            </Layout>
        );
    }
}

export default injectIntl(AddUpdateArticle)