import React, {Component} from "react";
import Sidebar from "../Components/Sidebar";
import {Button, Card, Input, Layout, Pagination, Row, Select} from "antd";
import styles from "./CSS Modules/Tags.module.css";
import {Link} from "react-router-dom";
import {FileTextOutlined, Loading3QuartersOutlined} from "@ant-design/icons";
import ArticlesCard from "../Components/ArticlesCard";

import Axios from "../axios";
import {injectIntl} from "react-intl";

const {Content} = Layout

class Articles extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            limit: 20,
            count: null,
            items: [],
            tags: [],
            find_article: '',
            find_tags: [],
            noItems: false,
            isLoaded: false
        }
    }

    componentDidMount() {
        this.getData()
        this.getTags()
    }

    getData = () => {
        Axios.get('/admin/article', {
            params: {
                limit: this.state.limit,
                page: this.state.page
            }
        })
            .then((res) => {
                this.setState({
                    count: res.data.count,
                    items: res.data.items
                })
            })
            .catch(() => {
                this.setState({
                    count: null,
                    page: 1,
                    items: []
                })
            })
    }

    getTags = () => {
        Axios.get('/tags')
            .then((res) => {
                let tags = res.data.map((item) => item.name)
                this.setState({
                    tags: tags,
                    isLoaded: true
                })
            })
            .catch(() => {
                this.setState({
                    tags: [],
                    isLoaded: true
                })
            })
    }

    onPageChange = (page) => {
        this.setState({
            page: page
        })
        if (this.state.find_article === '' && this.state.find_tags.length === 0) {
            Axios.get('/admin/article', {
                params: {
                    limit: this.state.limit,
                    page: page
                }
            })
                .then(res => {
                    this.setState({
                        count: res.data.count,
                        items: res.data.items
                    })
                })
                .catch(() => {
                    this.setState({
                        page: 1,
                        items: []
                    })
                })
                .finally(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                })
        } else {
            this.findArticles(page)
        }
    }

    findArticles = (page) => {
        Axios.get('/admin/articles-filter', {
            params: {
                title: this.state.find_article,
                tags: this.state.find_tags,
                limit: this.state.limit,
                page: page
            }
        })
            .then((res) => {
                this.setState({
                    page: page,
                    count: res.data.count,
                    items: res.data.items
                })
            })
            .catch(() => {
                this.setState({
                    page: 1,
                    count: null,
                    items: []
                })
            })
            .finally(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            })
    }

    onFindButtonClick = () => {
        this.findArticles(1)
    }

    onFindArticleNameChange = (event) => {
        this.setState({
            find_article: event.target.value
        })
    }

    onTagsChange = (value) => {
        this.setState({
            find_tags: value
        })
    }

    render() {
        return (
            <Layout>
                <Sidebar selected="articles"/>
                <Layout className={styles["site-layout"]} style={{marginLeft: '13vw'}}>
                    <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
                        <div className={styles["site-layout-background"]} style={{padding: 24}}>
                            <Row>
                                <Card bordered={true}
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          width: 300
                                      }}>
                                    <Button style={{padding: '40px 40px 70px 40px', fontSize: '20px'}}
                                            onClick={this.onAddButtonClick} type="primary" block>
                                        <Link to='/article'>
                                            <FileTextOutlined/>{this.intl.formatMessage({id: 'article.add'})}
                                        </Link>
                                    </Button>
                                </Card>

                                <Card title={this.intl.formatMessage({id: 'article.find'})} bordered={true}
                                      style={{width: 300}}>
                                    <Input value={this.state.find_article} onPressEnter={this.onFindButtonClick}
                                           onChange={this.onFindArticleNameChange}
                                           placeholder={this.intl.formatMessage({id: 'article.placeholder.name'})}
                                           prefix={<FileTextOutlined/>}/>
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        allowClear
                                        style={{width: '100%'}}
                                        placeholder={this.intl.formatMessage({id: 'article.placeholder.tags'})}
                                        onChange={this.onTagsChange}
                                        value={this.state.find_tags}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {this.state.tags.map((tag) => {
                                            return <Select.Option key={tag} value={tag}>{tag}</Select.Option>
                                        })}
                                    </Select>
                                    <Button onClick={this.onFindButtonClick} type="primary" block>
                                        {this.intl.formatMessage({id: 'article.find'})}
                                    </Button>
                                </Card>
                            </Row>

                            <div className={styles["site-card-wrapper"]}>
                                <Row gutter={[8, 8]}>
                                    {
                                        this.state.isLoaded ?
                                        this.state.items.length !== 0 ? this.state.items.map(item => {
                                                return <ArticlesCard key={item.id} item={item}/>
                                            })
                                            :
                                            <h1>{this.intl.formatMessage({id: 'no-data'})}</h1>
                                        :
                                        <Loading3QuartersOutlined style={{ fontSize: '50px', margin: '0 auto' }} spin />
                                    }
                                </Row>
                            </div>

                            <hr style={{margin: '30px 0'}}/>

                            <Pagination simple style={{marginBottom: '40px', textAlign: 'center'}}
                                        onChange={this.onPageChange}
                                        current={this.state.page}
                                        pageSize={this.state.limit} total={this.state.count}/>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default injectIntl(Articles)