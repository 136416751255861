import {Component} from "react";
import {Form, Input, Button, message} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';

import 'antd/dist/antd.css'
import styles from "./CSS Modules/Login.module.css"
import UserContext from "../Context/UserContext";
import Axios from "../axios";
import {Redirect} from "react-router-dom";


class Login extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            auth: false,
            reset_password: false
        }
    }

    onFinish = (values) => {
        let params = new URLSearchParams();
        params.append('name', values.name)
        params.append('password', values.password)
        this.context.updateContext('isAuthed', true)
     
        Axios.post('/login', params)
            .then((res) => {
                if (res.data.isAdmin) {
                    this.context.updateContext('isAuthed', true)
                    this.setState({
                        auth: true
                    })
                }
            })
            .catch(() => {
                message.error('Error')
            })
    }

    onResetPasswordClick = () => {
        this.setState({
            reset_password: true
        })
    }

    render() {
        return (
            <Form
                name="normal_login"
                className={styles['login-form']}
                onFinish={this.onFinish}
            >
                <Form.Item>
                    <h1 style={{textAlign: "center"}}>Shirin Meva</h1>
                </Form.Item>

                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your name!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined className={styles['site-form-item-icon']}/>} placeholder="Username"/>
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            min: 8,
                            message: 'Please input your Password! Minimum 8 symbols!',
                        },
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className={styles['site-form-item-icon']}/>}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item style={{textAlign: 'center'}}>
                    <Button onClick={this.onResetPasswordClick}> Forgot the password? </Button>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className={styles['login-form-button']}>
                        Log in
                    </Button>
                </Form.Item>
                {
                    this.state.auth ? <Redirect to='/tags'/> : null
                }
                {
                    this.state.reset_password ? <Redirect to='/get-reset-password' /> : null
                }
            </Form>
        )
    }
}

export default Login