import React, {Component} from 'react';
import 'antd/dist/antd.css';
import { Result, Button } from 'antd';
import {Redirect} from "react-router-dom";

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backHome: false
        }
    }
    onBackHome = () => {
        this.setState({
            backHome: true
        })
    }

    render() {
        if(this.state.backHome) {
            return <Redirect to='/tags'/>
        }
        else {
            return (
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button onClick={this.onBackHome} type="primary">Back Home</Button>}
                />
            )
        }
    }
}

export default NotFound