import React, { Component } from "react";
import { Button, Card, Col, message } from "antd";

import { Link } from 'react-router-dom'
import Axios from "../axios";
import { injectIntl } from "react-intl";

class CountryCard extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            name: this.props.item.name,
            currency_symbol: this.props.item.currency_symbol,
            visibility: this.props.item.visibility,
        }
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-company/' + this.state.id)
            .then(() => {
                this.setState({
                    visibility: 0
                })
                message.success(this.intl.formatMessage({ id: 'country.delete.success' }))
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'country.delete.error' }))
            })
    }

    onRestoreClick = () => {
        Axios.post('/admin/restore-country/' + this.state.id)
            .then(() => {
                this.setState({
                    visibility: 1
                })
                message.success(this.intl.formatMessage({ id: 'country.restore.success' }))
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'country.restore.error' }))
            })
    }

    render() {
        return (
            <Col span={6}>
                <Card>
                    <Link to={'/country/' + this.state.id}>
                        <Card style={{ height: "100px" }} bordered={true}>
                            <h3>Davlat: {this.state.name}</h3>
                            <h3>Pul birligi: {this.state.currency_symbol}</h3>
                        </Card>
                    </Link>
                    {this.state.visibility === 1 ?
                        <Button type="danger" block onClick={this.onDeleteClick}>
                            {this.intl.formatMessage({ id: 'country.delete' })}
                        </Button> :
                        <Button type="primary" block onClick={this.onRestoreClick}>
                            {this.intl.formatMessage({ id: 'country.restore' })}
                        </Button>}
                </Card>

            </Col>
        )
    }
}

export default injectIntl(CountryCard)