import { Component } from 'react'
import { Button, Card, Input, Layout, Row, DatePicker, message, Select, Pagination } from "antd";
import Sidebar from "../Components/Sidebar";
import styles from "./CSS Modules/Tags.module.css";
import { FormOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import EventsCard from "../Components/EventsCard";
import Axios from "../axios";
import { injectIntl } from "react-intl";

const { Option } = Select
const { Content } = Layout

class Events extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            add_event_value: '',
            add_event_date_value: '',
            article_id: null,
            find_event_value: '',
            find_date_value: null,
            page: 1,
            limit: 20,
            count: null,
            items: [],
            articles: [],
            isLoaded: false
        }
    }

    componentDidMount() {
        this.getArticles(this.getData)
    }

    getData = () => {
        Axios.get('/admin/event', {
            params: {
                limit: this.state.limit,
                page: this.state.page
            }
        })
            .then((res) => {
                this.setState({
                    page: 1,
                    count: res.data.count,
                    items: res.data.items,
                    isLoaded: true
                })
            })
            .catch(() => {
                this.setState({
                    page: 1,
                    count: null,
                    items: [],
                    isLoaded: true
                })
            })
    }

    findEvents = (page) => {
        if (this.state.find_event_value !== '' || this.state.find_date_value !== '') {
            Axios.get('/admin/filtered-events', {
                params: {
                    name: this.state.find_event_value,
                    date: this.state.find_date_value,
                    limit: this.state.limit,
                    page: page
                }
            })
                .then((res) => {
                    this.setState({
                        page: page,
                        count: res.data.count,
                        items: res.data.items
                    })
                })
                .catch(() => {
                    this.setState({
                        page: 1,
                        count: null,
                        items: []
                    })
                })
                .finally(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                })
        } else {
            this.getData()
        }
    }

    onFindButtonClick = () => {
        this.findEvents(1)
    }

    getArticles = (callback) => {
        Axios.get('/admin/articles-for-events')
            .then((res) => {
                this.setState({
                    articles: res.data
                }, () => callback())
            })
            .catch(() => {
                this.setState({
                    articles: []
                })
            })
    }

    onPageChange = (page) => {
        this.setState({
            page: page
        })
        if (this.state.find_event_value === '' && this.state.find_date_value === null) {
            Axios.get('/admin/event', {
                params: {
                    limit: this.state.limit,
                    page: page
                }
            })
                .then(res => {
                    this.setState({
                        count: res.data.count,
                        items: res.data.items
                    })
                })
                .catch(() => {
                    this.setState({
                        page: 1,
                        items: []
                    })
                })
                .finally(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                })
        } else {
            this.findEvents(page)
        }
    }

    onEventNameChange = (event) => {
        this.setState({
            add_event_value: event.target.value
        })
    }

    onFindEventNameChange = (event) => {
        this.setState({
            find_event_value: event.target.value
        })
    }

    onDateChange = (value, dateString) => {
        this.setState({
            add_event_date_value: dateString
        })
    }

    onFindDateChange = (value, dateString) => {
        this.setState({
            find_date_value: dateString
        }, () => {
        })
    }

    onArticleChange = (value) => {
        this.setState({
            article_id: value
        })
    }

    onAddButtonClick = (event) => {
        event.preventDefault()

        if (this.state.add_event_value !== '' && this.state.add_event_date_value !== '') {
            event.target.disabled = true

            let params = new URLSearchParams();
            params.append('name', this.state.add_event_value)
            params.append('date', this.state.add_event_date_value)
            params.append('article_id', this.state.article_id)

            Axios.post('/admin/event', params)
                .then(() => {
                    message.success(this.intl.formatMessage({ id: 'event.add.success' }))
                    event.target.disabled = false
                    this.getData()
                })
                .catch((err) => {
                    if (err.response.status === 406) {
                        message.error(this.intl.formatMessage({ id: "event.add.error.already-exist" }))
                    } else message.error(this.intl.formatMessage({ id: 'event.add.error' }))
                    event.target.disabled = false
                })
        } else {
            message.error(this.intl.formatMessage({ id: 'event.add.error.input-empty' }))
        }
    }

    render() {
        return (
            <Layout>
                <Sidebar selected={'events'} />
                <Layout className={styles["site-layout"]} style={{ marginLeft: '13vw' }}>
                    <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                        <div className={styles["site-layout-background"]} style={{ padding: 24 }}>
                            <Row>
                                <Card title={this.intl.formatMessage({ id: 'event.add' })} bordered={true}
                                    style={{ width: 300 }}>
                                    <Input value={this.state.add_event_value} onChange={this.onEventNameChange}
                                        onPressEnter={this.onAddButtonClick}
                                        placeholder={this.intl.formatMessage({ id: 'event.placeholder.name' })}
                                        prefix={<FormOutlined />} />
                                    <DatePicker placeholder={this.intl.formatMessage({ id: 'event.placeholder.date' })}
                                        style={{ width: '100%' }} showTime onChange={this.onDateChange} />

                                    <h3 style={{ marginTop: '20px' }}>{this.intl.formatMessage({ id: 'event.article' })}</h3>
                                    <Select
                                        showSearch
                                        allowClear
                                        mode='single'
                                        value={this.state.article_id}
                                        style={{ marginBottom: '20px', width: '100%' }}
                                        onChange={this.onArticleChange}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {
                                            this.state.articles.map((article) => {
                                                return <Option key={article.id} value={article.id}>
                                                    {article.title}
                                                </Option>
                                            })
                                        }
                                    </Select>

                                    <Button onClick={this.onAddButtonClick} type="primary" block>
                                        {this.intl.formatMessage({ id: 'event.add' })}
                                    </Button>
                                </Card>

                                <Card title={this.intl.formatMessage({ id: 'event.find' })} bordered={true}
                                    style={{ width: 300 }}>
                                    <Input value={this.state.find_event_value} onChange={this.onFindEventNameChange}
                                        onPressEnter={this.onFindButtonClick}
                                        placeholder={this.intl.formatMessage({ id: 'event.placeholder.name' })}
                                        prefix={<FormOutlined />} />

                                    <DatePicker placeholder={this.intl.formatMessage({ id: 'event.placeholder.date' })}
                                        style={{ width: '100%' }}
                                        onChange={this.onFindDateChange}
                                    />
                                    <Button style={{ marginTop: '105px' }} onClick={this.onFindButtonClick} type="primary"
                                        block>
                                        {this.intl.formatMessage({ id: 'event.find' })}
                                    </Button>
                                </Card>
                            </Row>

                            <hr style={{ margin: '30px 0' }} />

                            <div className={styles["site-card-wrapper"]}>
                                <Row gutter={[8, 8]}>
                                    {
                                        this.state.isLoaded ?
                                            this.state.items.length !== 0 ? this.state.items.map(item => <EventsCard
                                                key={item.id} item={item} articles={this.state.articles} />) :
                                                <h1>{this.intl.formatMessage({ id: 'no-data' })}</h1>
                                            :
                                            <Loading3QuartersOutlined style={{ fontSize: '50px', margin: '0 auto' }}
                                                spin />
                                    }
                                </Row>
                            </div>

                            <hr style={{ margin: '30px 0' }} />

                            <Pagination simple style={{ marginBottom: '40px', textAlign: 'center' }}
                                onChange={this.onPageChange}
                                current={this.state.page}
                                pageSize={this.state.limit} total={this.state.count} />
                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default injectIntl(Events)