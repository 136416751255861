import React, { Component } from "react";
import moment from "moment";
import {
  DatePicker,
  Button,
  Form,
  Input,
  Layout,
  Row,
  Col,
  message,
  Select,
} from "antd";
import Sidebar from "../Components/Sidebar";
import "antd/dist/antd.css";
import styles from "./CSS Modules/Company.model.css";

import Axios from "../axios";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import "rc-color-picker/assets/index.css";
const { Content } = Layout;

const { Option } = Select;

class AddUpdateMap extends Component {
  intl = this.props.intl;
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      id: null,
      date: '',
      region: '',
      rate: '',
      product: '',
      products: [],
      regions: [],
      notExist: false,
      isAdded: false,
      isUpdated: false,
      page: 1,
      limit: 20,
    };
  }

  componentDidMount() {
    this.setState({
      regions:[
        'uz-fa',
        'uz-tk',
        'uz-an',
        'uz-ng',
        'uz-ji',
        'uz-si',
        'uz-ta',
        'uz-bu',
        'uz-kh',
        'uz-qr',
        'uz-nw',
        'uz-sa',
        'uz-qa',
        'uz-su'
      ]
    })
    this.getProduct();
    this.getData();
  }

  getProduct = () => {
    Axios.get("/products/map", {
      params: {
        limit: this.state.limit,
        page: this.state.page
      }
    })
      .then((result) => {
        this.setState({
          products: result.data.items,
        });
      })
      .catch(() => {
        this.setState({
          notExist: true,
        });
      });
  };

  getData = () => {
    let state = this.props.location.state;
    if (typeof state !== "undefined") {
      this.setState({
        id: state.id,
        date: state.date,
        region: state.region,
        rate: state.rate,
        product: state.product == null ? '' : state.product,
        isAdded: false,
      });
    } else {
      if (this.state.type === "Update")
        Axios.get("/maps/" + this.props.computedMatch.params.id)
          .then((result) => {
            let state = result.data[0];
            this.setState({
              id: state.id,
              date: state.date,
              region: state.region,
              rate: state.rate,
              product: state.product == null ? '' : state.product,
              isAdded: false,
            });
          })
          .catch(() => {
            this.setState({
              notExist: true,
            });
          });
    }
  };

  onAddClick = (event) => {
    let productIsNull = false;
    if (!productIsNull) {
      event.target.disabled = true;
      let params = this.createForm();
      Axios.post("/admin/map", params)
        .then(() => {
          message.error(this.intl.formatMessage({ id: "company.add.success" }));
          this.setState({
            isAdded: true,
          });
        })
        .catch((err) => {
          if (err.response.status === 406)
            message.error(
              this.intl.formatMessage({ id: "member.add.error.already-exist" })
            );
          else
            message.error(this.intl.formatMessage({ id: "company.add.error" }));
          event.target.disabled = false;
        });
    } else {
      message.error(this.intl.formatMessage({ id: "company.products.error" }));
    }
  };

  onUpdateClick = () => {
    let productIsNull = false;
    if (!productIsNull) {
      let params = this.createFormUpdate();
      Axios.post("/admin/map/" + this.state.id, params)
        .then((res) => {
          this.setState({
            isAdded: true,
          });
          message.success(
            this.intl.formatMessage({ id: "company.update.success" })
          );
        })
        .catch(() => {
          message.error(
            this.intl.formatMessage({ id: "company.update.error" })
          );
        });
    } else {
      message.error(this.intl.formatMessage({ id: "company.products.error" }));
    }
  };


  createForm = () => {
    let params = new URLSearchParams();
    params.append('product', this.state.product)
    params.append('region', this.state.region)
    params.append('date', this.state.date)
    params.append('rate', this.state.rate)
    return params;
  };

  createFormUpdate = () => {
    let params = new URLSearchParams();
    params.append('product', this.state.product)
    params.append('region', this.state.region)
    params.append('date', this.state.date)
    params.append('rate', this.state.rate)
    return params;
  };

  onPricesLocalDateChange = (event) => {
    this.setState({
      date: moment(new Date(event._d)).format('YYYY-MM-DD')
    });
  };
  onProducChange = (event) => {
    this.setState({
      product: event.target.value,
    });
  };

  onRate = (event) => {
    this.setState({
      rate: event.target.value,
    });
  };

  onVolumeChange = (event) => {
    this.setState({
      volume: event.target.value,
    });
  };

  onChange(date, dateString) {
    this.setState({
      open: date._d,
    });
  }

  handleProduct = (value) => {
    this.setState({ product: value });
  };

  handleRegion = (value) => {
    this.setState({ region: value });
  };

  getDateFormat = date => {
    var d = moment(date);
    return date && d.isValid() ? d : '';
  };
  render() {
    const optionsProduct = this.state.products.map((d) => (
      <Option key={d.name}>{d.name}</Option>
    ));
    const optionRegion = this.state.regions.map((d) => (
      <Option key={d}>{d}</Option>
    ));
    return (
      <Layout>
        <Sidebar selected={"maps"} />
        <Layout
          className={styles["site-layout"]}
          style={{ marginLeft: "28vw" }}
        >
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
              width: "1000px",
            }}
          >
            <Form
              name="form_name"
              fields={[
                {
                  name: ["date"],
                  value: this.state.date
                },
                {
                  name: ["region"],
                  value: this.state.region
                },
                {
                  name: ["rate"],
                  value: this.state.rate
                },
                {
                  name: ["products"],
                  value: this.state.products
                }
              ]}
            >
              <Form.Item>
                {this.state.type === "Add" ? (
                  <h1 style={{ textAlign: "center" }}>
                    {this.intl.formatMessage({ id: "company.add" })}
                  </h1>
                ) : (
                  <h1 style={{ textAlign: "center" }}>
                    {this.intl.formatMessage({ id: "company.update" })}
                  </h1>
                )}
              </Form.Item>
              <Row style={{ marginBottom: "20px" }}>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.product}
                    defaultActiveFirstOption={false}
                    onChange={this.handleProduct}
                    notFoundContent={null}
                  >
                    {optionsProduct}
                  </Select>
                </Col>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.region}
                    defaultActiveFirstOption={false}
                    onChange={this.handleRegion}
                    notFoundContent={null}
                  >
                    {optionRegion}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <DatePicker
                    placeholder={this.intl.formatMessage({ id: 'chart.placeholder.product_date' })}
                    format={'MM/YYYY'}
                    picker="month"
                    value={this.getDateFormat(this.state.date)}
                    onChange={this.onPricesLocalDateChange}
                  />
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="rate"
                    rules={[{ required: true }]} >
                    <Input
                      onChange={this.onRate}
                      placeholder={this.intl.formatMessage({ id: 'chart.placeholder.product_from' })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                {this.state.type === "Add" ? (
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={this.onAddClick}
                    className={styles["login-form-button"]}
                  >
                    {this.intl.formatMessage({ id: "company.add" })}
                  </Button>
                ) : (
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={this.onUpdateClick}
                    className={styles["login-form-button"]}
                  >
                    {this.intl.formatMessage({ id: "company.update" })}
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Content>
        </Layout>
        {this.state.isAdded ? <Redirect to="/maps" /> : null}
        {/* {this.state.notExist && <Redirect to="/404" />} */}
      </Layout>
    );
  }
}

export default injectIntl(AddUpdateMap);
