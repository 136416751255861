import React, { Component } from "react";
import { Button, Card, Col, message } from "antd";

import { Link } from 'react-router-dom'
import Axios from "../axios";
import { injectIntl } from "react-intl";

class ChartCard extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id,
            product: this.props.item.product,
            country: this.props.item.country,
            volume: this.props.item.volume,
        }
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-chart/' + this.state.id)
            .then(() => {
                this.setState({
                    visibility: 0
                })
                message.success(this.intl.formatMessage({ id: 'company.delete.success' }))
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'company.delete.error' }))
            })
    }

    onRestoreClick = () => {
        Axios.post('/admin/restore-chart/' + this.state.id)
            .then(() => {
                this.setState({
                    visibility: 1
                })
                message.success(this.intl.formatMessage({ id: 'company.restore.success' }))
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'company.restore.error' }))
            })
    }

    render() {
        return (
            <Col span={6}>
                <Link to={'/chart/' + this.state.id}>
                    <Card style={{ height: "150px" }} title={this.state.product} bordered={true}>
                        <p>{this.state.product}</p>
                        <p>{this.state.country}</p>
                        <p>{this.state.volume}</p>
                    </Card>
                </Link>
                {this.state.visibility === 1 ?
                    <Button type="danger" block onClick={this.onDeleteClick}>
                        {this.intl.formatMessage({ id: 'company.delete' })}
                    </Button> :
                    <Button type="primary" block onClick={this.onRestoreClick}>
                        {this.intl.formatMessage({ id: 'company.restore' })}
                    </Button>}
            </Col>
        )
    }
}

export default injectIntl(ChartCard)