import React, { Component } from "react";
import { Button, Form, Input, Layout, Row, Col, message, Upload, Select } from "antd";
import Sidebar from "../Components/Sidebar";
import Axios from "../axios";
import { FileImageOutlined, UserOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import styles from "./CSS Modules/Login.module.css";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import "jodit/build/jodit.min.css";
import JoditEditor from "jodit-react";
const { Option } = Select;
const { Dragger } = Upload;
const { Content } = Layout;

class AddUpdateVedio extends Component {
    intl = this.props.intl;

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            html: null,
            id: null,
            image_preview: "",
            image: null,
            name: "",
            section: "",
            sectionList: [],
            link: "",
            notExist: false,
            isAdded: false,
            isUpdated: false,
        };
    }

    componentDidMount() {
        if (this.props.computedMatch.params.id) this.getData();
        this.getVedioList()
    }

    getVedioList = () => {
        Axios.get("/section/video/")
            .then((result) => {
                this.setState({
                    sectionList: result.data.items
                })
            })
            .catch(() => {
                this.setState({
                    notExist: true,
                });
            });
    }

    getData = () => {
        let state = this.props.location.state;
        if (typeof state !== "undefined") {
            this.setState({
                id: state.id,
                name: state.name,
                section: state.section,
                html: state.html,
                link: state.link,
                image_preview: state.image,
                image: state.image,
            });
        } else {
            Axios.get("/video/" + this.props.computedMatch.params.id)
                .then((result) => {
                    let state = result.data;
                    console.log(state.link)
                    this.setState({
                        id: state.id,
                        name: state.name,
                        section: state.section,
                        html: state.html,
                        link: state.link,
                        image_preview: state.image,
                        image: state.image,
                    });
                })
                .catch(() => {
                    this.setState({
                        notExist: true,
                    });
                });
        }

        // setTimeout(() => console.log(this.state), 2000);
    };

    createFormData = () => {
        let formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("section", this.state.section);
        formData.append("image", this.state.image);
        formData.append("html", this.state.html);
        formData.append("link", this.state.link);
        return formData;
    };

    onAddClick = (event) => {
        event.target.disabled = true;
        let formData = this.createFormData();
        Axios.post("/admin/videos", formData)
            .then((res) => {
                this.setState({
                    isAdded: true,
                });
            })
            .catch((err) => {
                if (err.response.status === 406)
                    message.error(
                        this.intl.formatMessage({ id: "video.add.error.already-exist" })
                    );
                else
                    message.error(this.intl.formatMessage({ id: "video.add.error" }));
                event.target.disabled = false;
            });
    };

    onUpdateClick = () => {
        let formData = this.createFormData();
        Axios.post("/admin/update-video/" + this.state.id, formData)
            .then((res) => {
                this.setState({
                    isUpdated: true,
                });
            })
            .catch((err) => {
                message.error(this.intl.formatMessage({ id: "video.update.error" }));
            });
    };

    onNameChange = (event) => {
        this.setState({
            name: event.target.value,
        });
    };

    onSectionChange = (event) => {
        console.log(event)
        this.setState({
            section: event,
        });
    };

    onLinkChange = (event) => {
        this.setState({
            link: event.target.value,
        });
    };

    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };

    render() {
        return (
            <Layout>
                <Sidebar selected={"videos"} />
                <Layout
                    className={styles["site-layout"]}
                    style={{ marginLeft: "13vw" }}
                >
                    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                        <Form
                            name="form_name"
                            fields={[
                                {
                                    name: ["name"],
                                    value: this.state.name,
                                },
                                {
                                    name: ["section"],
                                    value: this.state.section,
                                },
                                {
                                    name: ["link"],
                                    value: this.state.link,
                                },
                                {
                                    name: ["html"],
                                    value: this.state.html,
                                }

                            ]}
                        >
                            <Form.Item>
                                {this.state.type === "Add" ? (
                                    <h1 style={{ textAlign: "center" }}>
                                        {this.intl.formatMessage({ id: "video.add" })}
                                    </h1>
                                ) : (
                                    <h1 style={{ textAlign: "center" }}>
                                        {this.intl.formatMessage({ id: "video.update" })}
                                    </h1>
                                )}
                            </Form.Item>
                            <Row>
                                <Col span={12} offset={6}>
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({
                                                    id: "video.warning.name",
                                                }),
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={
                                                <UserOutlined
                                                    className={styles["site-form-item-icon"]}
                                                />
                                            }
                                            placeholder={this.intl.formatMessage({
                                                id: "video.placeholder.name",
                                            })}
                                            value={this.state.name}
                                            onChange={this.onNameChange}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="section"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({
                                                    id: "video.warning.name",
                                                }),
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange={this.onSectionChange}
                                        >
                                            {
                                                this.state.sectionList.map((section, index) => {
                                                    return <Option key={section.name} >{section.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="link"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({
                                                    id: "video.warning.link",
                                                }),
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={
                                                <UserOutlined
                                                    className={styles["site-form-item-icon"]}
                                                />
                                            }
                                            placeholder={this.intl.formatMessage({
                                                id: "video.warning.link",
                                            })}
                                            value={this.state.link}
                                            onChange={this.onLinkChange}
                                        />
                                    </Form.Item>

                                    <Col span={12} offset={6}>
                                        {this.state.image_preview === "" ? (
                                            <h3
                                                style={{
                                                    padding: "10px",
                                                    textAlign: "center",
                                                    border: "1px solid black",
                                                    width: "100%",
                                                }}
                                            >
                                                {this.intl.formatMessage({
                                                    id: "article.placeholder.preview-image",
                                                })}
                                            </h3>
                                        ) : (
                                            <img src={this.state.image_preview} width="100%" alt="" />
                                        )}
                                    </Col>
                                    <Form.Item name="preview_image">
                                        <Dragger
                                            {...{
                                                name: "image",
                                                multiple: false,
                                                maxCount: 1,
                                                showUploadList: false,
                                            }}
                                            beforeUpload={(file) => {
                                                if (
                                                    file.type !== "image/png" ||
                                                    file.type !== "image/jpeg" ||
                                                    file.type !== "image/jpg"
                                                ) {
                                                    this.setState({
                                                        image_preview: window.URL.createObjectURL(file),
                                                        image: file,
                                                    });
                                                    return false;
                                                } else {
                                                    message.error(
                                                        file.name +
                                                        " " +
                                                        this.intl.formatMessage({
                                                            id: "article.file-error.image",
                                                        })
                                                    );
                                                    return false;
                                                }
                                            }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <FileImageOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                {this.intl.formatMessage({
                                                    id: "article.drag.preview-image",
                                                })}
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                    <Form.Item>
                                        <JoditEditor
                                            value={this.state.html}
                                            onChange={content => {
                                                this.setState({ html: content })
                                            }
                                            }
                                        />
                                        {
                                            this.state.type === 'Add' ?
                                                <Button style={{ marginTop: '50px' }} block type="primary" htmlType="submit"
                                                    onClick={this.onAddClick}
                                                    className={styles['login-form-button']}>
                                                    {this.intl.formatMessage({ id: 'video.add' })}
                                                </Button>
                                                :
                                                <Button style={{ marginTop: '50px' }} block type="primary" htmlType="submit"
                                                    onClick={this.onUpdateClick}
                                                    className={styles['login-form-button']}>
                                                    {this.intl.formatMessage({ id: 'video.update' })}
                                                </Button>
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Content>
                </Layout>
                {this.state.notExist && <Redirect to="/404" />}
                {this.state.isAdded || this.state.isUpdated ? (
                    <Redirect to="/videos" />
                ) : null}
            </Layout>
        );
    }
}

export default injectIntl(AddUpdateVedio);
