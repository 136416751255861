import React, { Component } from "react";
import { Button, Card, Col, message } from "antd";

import { Link } from 'react-router-dom'
import Axios from "../axios";
import { injectIntl } from "react-intl";

class MapCard extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            product: this.props.item.product,
            date:this.props.item.date
        }
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-map/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'country.delete.success' }))
                this.props.onDelete()
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'country.delete.error' }))
            })
    }

    render() {
        return (
            <Col span={6}>
                <Link to={'/map/' + this.state.id}>
                    <Card style={{ height: "100px" }} bordered={true}>
                        <h3>Mahsulot  nomi: {this.state.product}</h3>
                        <h3>Mahsulot  vaqti: {this.state.date}</h3>
                    </Card>
                </Link>
                <Button type="danger" block onClick={this.onDeleteClick}>
                    {this.intl.formatMessage({ id: 'country.delete' })}
                </Button>
            </Col>
        )
    }
}

export default injectIntl(MapCard)