import React, {Component} from "react";
import {Button, Card, Input, Col, Row, message, DatePicker, Select} from "antd";
import {FormOutlined} from "@ant-design/icons";

import moment from 'moment'

import 'antd/dist/antd.css'
import Axios from "../axios";
import {injectIntl} from "react-intl";

const {Option} = Select

message.config({
    top: 0,
    duration: 0.5,
});

class EventsCard extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id,
            name: this.props.item.name,
            date: moment(this.props.item.date).utcOffset(0, false),
            dataString: moment(this.props.item.date).utcOffset(0, false).format('YYYY-MM-DD HH:mm:ss'),
            article_id: this.props.item.article_id,
            visibility: this.props.item.visibility,
            articles: this.props.articles
        }
    }

    onEventNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    onDateChange = (value, dataString) => {
        this.setState({
            date: value,
            dataString: dataString
        })
    }

    onArticleChange = (value) => {
        this.setState({
            article_id: value
        })
    }

    onUpdateClick = () => {
        if (this.state.name !== '' && this.state.date !== null) {
            let params = new URLSearchParams();
            params.append('name', this.state.name)
            params.append('date', this.state.dataString)
            params.append('article_id', this.state.article_id)

            Axios.post('/admin/update-event/' + this.state.id, params)
                .then(() => {
                    message.success(this.intl.formatMessage({id: 'event.update.success'}))
                })
                .catch(() => {
                    message.error(this.intl.formatMessage({id: 'event.update.error'}))
                })
        }
        else {
            message.error(this.intl.formatMessage({id: 'event.add.error.input-empty'}))
        }
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-event/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({id: 'event.delete.success'}))
                this.setState({
                    visibility: 0
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({id: 'event.update.error'}))
            })
    }

    onRestoreClick = () => {
        Axios.post('/admin/restore-event/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({id: 'event.restore.success'}))
                this.setState({
                    visibility: 1
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({id: 'event.restore.error'}))
            })
    }

    render() {
        return (
            <Col span={6}>
                <Card bordered={true}>
                    <Input value={this.state.name} onChange={this.onEventNameChange}
                           placeholder={this.intl.formatMessage({id: 'event.placeholder.name'})}
                           prefix={<FormOutlined/>}/>
                    <DatePicker placeholder={this.intl.formatMessage({id: 'event.placeholder.date'})}
                                style={{width: '100%'}} value={this.state.date} showTime onChange={this.onDateChange}
                    />

                    <h3 style={{marginTop: '20px'}}>{this.intl.formatMessage({id: 'event.article'})}</h3>
                    <Select
                        mode='single'
                        showSearch
                        allowClear
                        style={{marginBottom: '20px', width: '100%'}}
                        value={this.state.article_id}
                        onChange={this.onArticleChange}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            this.state.articles.map((article) => {
                                return <Option key={article.id} value={article.id}>
                                    {article.title}
                                </Option>
                            })
                        }
                    </Select>

                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Button type="primary" block onClick={this.onUpdateClick}>
                                {this.intl.formatMessage({id: 'event.update'})}
                            </Button>
                        </Col>
                        <Col span={12}>
                            {this.state.visibility === 1 ?
                                <Button type="danger" block onClick={this.onDeleteClick}>
                                    {this.intl.formatMessage({id: 'event.delete'})}
                                </Button> :
                                <Button type="primary" block onClick={this.onRestoreClick}>
                                    {this.intl.formatMessage({id: 'event.restore'})}
                                </Button>}
                        </Col>
                    </Row>
                </Card>
            </Col>
        )
    }
}

export default injectIntl(EventsCard)