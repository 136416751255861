import React, { Component } from "react";
import { Col, Card } from "antd";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
const { Meta } = Card;
class MagazineContentCard extends Component {
    intl = this.props.intl;
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            name: this.props.item.name,
            image: this.props.item.image,
            numPages: null,
            pageNumber: 1,
        };
    }

    render() {
        return (
            <Col>
                <Card>
                    <Link
                        to={{
                            pathname: "/magazine-content/" + this.state.id,
                            state: {
                                id: this.state.id,
                                name: this.state.name,
                                image: this.state.image,
                            },
                        }}
                    >
                        <Card
                            hoverable
                            style={{ width: 240, height: "90%" }}
                            cover={
                                <img
                                    style={{ height: "200px", objectFit: "contain" }}
                                    alt={this.state.name}
                                    src={this.state.image}
                                />
                            }
                        >
                            <Meta title={this.state.name} />
                        </Card>
                    </Link>
                </Card>

            </Col>
        );
    }
}

export default injectIntl(MagazineContentCard);
