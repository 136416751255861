import React, { Component } from "react";
import { Button, Card, Col, Row, message } from "antd";
import { Link } from 'react-router-dom'

import { EyeOutlined } from '@ant-design/icons'

import 'antd/dist/antd.css'
import Axios from "../axios";
import { injectIntl } from "react-intl";

message.config({
    top: 0,
    duration: 0.5,
});

class ArticlesCards extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            title: this.props.item.title,
            author: this.props.item.author,
            number_of_views: this.props.item.number_of_views,
            updated_at: this.props.item.updated_at,
            created_at: this.props.item.created_at,
            visibility: this.props.item.visibility,
            isPinned: this.props.item.isPinned,
            isMain: this.props.item.isMain
        }
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-article/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'article.delete.success' }))
                this.setState({
                    visibility: 0
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'article.delete.error' }))
            })
    }

    onRestoreClick = () => {
        Axios.post('/admin/restore-article/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'article.restore.success' }))
                this.setState({
                    visibility: 1
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'article.restore.error' }))
            })
    }

    onPinClick = () => {
        Axios.post('/admin/add-article-pin/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'article.pin.success' }))
                this.setState({
                    isPinned: 1
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'article.pin.error' }))
            })
    }

    onUnpinClick = () => {
        Axios.post('/admin/delete-article-pin/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'article.unpin.success' }))
                this.setState({
                    isPinned: 0
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'article.unpin.error' }))
            })
    }

    onMainClick = () => {
        Axios.post('/admin/add-article-main/' + this.state.id)
            .then((res) => {
                message.success('success mained')
                this.setState({
                    isMain: 1
                })
            })
            .catch(() => {
                message.error('error mained')
            })
    }
    showTime = (time) => {
        let date = new Date(time);
        return date.toLocaleString()
    }

    render() {
        return (
            <Col span={6} style={{ height: "380px" }}>
                <Card>
                    <Link to={'/article/' + this.state.id}>
                        <Card title={this.state.title} bordered={true}>
                            <p>{this.state.author}</p>
                            <p>{this.intl.formatMessage({ id: 'article.text.last-updated' })} {this.showTime(this.state.updated_at)}</p>
                            <p>{this.intl.formatMessage({ id: 'article.text.created' })} {this.showTime(this.state.created_at)}</p>
                            <p><EyeOutlined /> {this.state.number_of_views}</p>
                        </Card>
                    </Link>
                    <Row style={{ marginBottom: "10px" }} >
                        <Col span={24}>
                            {
                                this.state.isMain === 1 ?
                                    <Button type="primary" block onClick={this.onMainClick}>
                                        Mained
                                        {/* {this.intl.formatMessage({ id: 'article.unpin' })} */}
                                    </Button>
                                    :
                                    <Button type="danger" block onClick={this.onMainClick}>
                                        IsMained
                                        {/* {this.intl.formatMessage({ id: 'article.pin' })} */}
                                    </Button>
                            }
                        </Col>

                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            {
                                this.state.isPinned ?
                                    <Button type="primary" block onClick={this.onUnpinClick}>
                                        {this.intl.formatMessage({ id: 'article.unpin' })}
                                    </Button>
                                    :
                                    <Button type="primary" block onClick={this.onPinClick}>
                                        {this.intl.formatMessage({ id: 'article.pin' })}
                                    </Button>
                            }
                        </Col>
                        <Col span={12}>
                            {this.state.visibility === 1 ?
                                <Button type="danger" block onClick={this.onDeleteClick}>
                                    {this.intl.formatMessage({ id: 'article.delete' })}
                                </Button> :
                                <Button type="primary" block onClick={this.onRestoreClick}>
                                    {this.intl.formatMessage({ id: 'article.restore' })}
                                </Button>}
                        </Col>
                    </Row>
                </Card>


            </Col>
        )
    }
}

export default injectIntl(ArticlesCards)