import React, { Component } from "react";
import { Button, Card, Col, message } from "antd";

import { Link } from 'react-router-dom'
import Axios from "../axios";
import { injectIntl } from "react-intl";

class MapProductCard extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            name: this.props.item.name,
        }
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-product/map/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'country.delete.success' }))
                this.props.onDelete()
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'country.delete.error' }))
            })
    }

    render() {
        return (
            <Col span={6}>
                <Card>
                    <Link to={'/product/map/' + this.state.id}>
                        <Card style={{ height: "100px" }} bordered={true}>
                            <h3>Mahsulot  nomi: {this.state.name}</h3>
                        </Card>
                    </Link>
                    <Button type="danger" block onClick={this.onDeleteClick}>
                        {this.intl.formatMessage({ id: 'country.delete' })}
                    </Button>
                </Card>
            </Col>
        )
    }
}

export default injectIntl(MapProductCard)