import React, {Component} from "react";
import {Button, Card, Input, Col, Row, message} from "antd";
import {TagsOutlined} from "@ant-design/icons";

import 'antd/dist/antd.css'
import Axios from "../axios";
import {injectIntl} from "react-intl";

message.config({
    top: 0,
    duration: 0.5,
});

class TagsCard extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            name: this.props.item.name,
            initial_name: this.props.item.name,
            visibility: this.props.item.visibility
        }
    }

    onActivityTypeNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    onUpdateClick = () => {
        let params = new URLSearchParams();
        params.append('name', this.state.name)

        Axios.post('/admin/update-activity-type/' + this.state.id, params)
            .then(() => {
                message.success(this.intl.formatMessage({id: "activity.update.success"}))
                this.setState({
                    initial_name: this.state.name
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({id: "activity.update.error"}))
                this.setState({
                    name: this.state.initial_name
                })
            })
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-activity-type/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({id: "activity.delete.success"}))
                this.setState({
                    visibility: 0
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({id: "activity.delete.success"}))
            })
    }

    onRestoreClick = () => {
        Axios.post('/admin/restore-activity-type/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({id: "activity.restore.success"}))
                this.setState({
                    visibility: 1
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({id: "activity.restore.error"}))
            })
    }

    render() {
        return (
            <Col span={6}>
                <Card bordered={true}>
                    <Input value={this.state.name} onChange={this.onActivityTypeNameChange}
                           placeholder="Activity type name"
                           prefix={<TagsOutlined/>}/>
                    <Row gutter={[16, 16]} style={{marginTop: '10px'}}>
                        <Col span={12}>
                            <Button type="primary" block onClick={this.onUpdateClick}>
                                {this.intl.formatMessage({id: "activity.update"})}
                            </Button>
                        </Col>
                        <Col span={12}>
                            {this.state.visibility === 1 ?
                                <Button type="danger" block onClick={this.onDeleteClick}>
                                    {this.intl.formatMessage({id: "activity.delete"})}
                                </Button> :
                                <Button type="primary" block onClick={this.onRestoreClick}>
                                    {this.intl.formatMessage({id: "activity.restore"})}
                                </Button>}
                        </Col>
                    </Row>
                </Card>
            </Col>
        )
    }
}

export default injectIntl(TagsCard)