import './App.css';

import { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"
import { UserProvider } from "./Context/UserContext";
import { IntlProvider } from 'react-intl';

import Axios from "./axios";

// Import Pages
import Login from "./Pages/Login";
import ProtectedRoute from "./Components/ProtectedRoute";
import Tags from "./Pages/Tags";
import ActivityTypes from "./Pages/ActivityTypes";
import Members from "./Pages/Members";
import AddUpdateMember from "./Pages/AddUpdateMember";
import AnorMembers from "./Pages/AnorMembers";
import AddUpdateAnorMember from "./Pages/AddUpdateAnorMember";
import NotFound from "./Components/404";
import Companies from "./Pages/Companies";
import AddUpdateCompany from "./Pages/AddUpdateCompany";
import AddUpdateArticle from "./Pages/AddUpdateArticle";
import Articles from "./Pages/Articles";
import Events from "./Pages/Events";
import Magazines from "./Pages/Magazines"
import AddUpdateMagazine from './Pages/AddUpdateMagazine';
import Videos from './Pages/Videos';
import AddUpdateVideo from './Pages/AddUpdateVideo';
import VideoList from './Pages/VideoList';
import AddUpdateListVideos from './Pages/AddUpdateListVideos';
import Charts from './Pages/Charts';
import AddUpdateCharts from './Pages/AddUpdateCharts';
import Chart from './Pages/Chart';
import AddUpdateChart from './Pages/AddUpdateChart';
import Analysis from './Pages/Analysis';
import AddUpdateAnalysis from './Pages/AddUpdateAnalysis';
import en from "./lang/en.json"
import ru from "./lang/ru.json"
import uz from "./lang/uz.json"
import ResetPassword from "./Pages/Reset password";
import 'pdf-viewer-reactjs-bulma-wrapped/css/main.css';
import Contents from './Pages/Contents';
import AddUpdateContent from './Pages/AddUpdateContent';
import MagazinesContent from './Pages/Magazines-Content';
import AddUpdateCountry from './Pages/AddUpdateCountry';
import AddUpdateMapProduct from './Pages/AddUpdateMapProduct';
import AddUpdateMap from './Pages/AddUpdateMap';
import AddUpdateCrops from './Pages/AddUpdateCrops';
import AddUpdatePresentation from './Pages/AddUpdatePresentation';
import Country from './Pages/Country';
import MapProducts from './Pages/MapProducts';
import Maps from './Pages/Maps';
import Crops from './Pages/Crops';
import Presentation from './Pages/Presentation';
import AnorEvents from './Pages/AnorEvents';
const messages = {
    uz: uz,
    en: en,
    ru: ru
};

class App extends Component {
    state = {
        lang: 'en',
        isAuthed: false,
        isLoading: true
    }

    updateContext = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    componentDidMount() {
        let lang = localStorage.getItem('lang')
        if (lang === null) {
            localStorage.setItem('lang', 'ru')
            this.setState({
                lang: 'ru'
            })
        } else {
            this.setState({
                lang: localStorage.getItem('lang')
            })
        }

        Axios.get('/auth')
            .then(() => {
                this.setState({
                    isAuthed: true,
                    isLoading: false
                })
            })
            .catch(() => {
                this.setState({
                    isAuthed: false,
                    isLoading: false
                })
            })
    }

    render() {
        if (this.state.isLoading || this._isMounted) {
            return <h1>Loading...</h1>
        } else {
            return (
                <UserProvider value={{ value: this.state, updateContext: this.updateContext }}>
                    <IntlProvider messages={messages[this.state.lang]} locale={this.state.lang}>
                        <BrowserRouter>
                            <Switch>
                                <Route exact path="/login" render={(props) => <Login {...props} />} />

                                <Route exact path="/get-reset-password" render={(props) => <ResetPassword type='Get' {...props} />} />

                                <Route exact path="/reset-password/:code" render={(props) => <ResetPassword type='Reset' {...props} />} />

                                <ProtectedRoute exact path="/tags" component={Tags} />
                                <ProtectedRoute exact path="/activity-types" component={ActivityTypes} />

                                <ProtectedRoute exact path="/anorevents" component={AnorEvents} />

                                <ProtectedRoute exact path="/members" component={Members} />
                                <ProtectedRoute exact path="/member" component={AddUpdateMember} type='Add' />
                                <ProtectedRoute exact path="/member/:id" component={AddUpdateMember} type='Update' />


                                <ProtectedRoute exact path="/anor/members" component={AnorMembers} />
                                <ProtectedRoute exact path="/anor/member" component={AddUpdateAnorMember} type='Add' />
                                <ProtectedRoute exact path="/anor/member/:id" component={AddUpdateAnorMember} type='Update' />

                                <ProtectedRoute exact path="/crops" component={Crops} />
                                <ProtectedRoute exact path="/crop" component={AddUpdateCrops} type='Add' />
                                <ProtectedRoute exact path="/crop/:id" component={AddUpdateCrops} type='Update' />

                                <ProtectedRoute exact path="/maps" component={Maps} />
                                <ProtectedRoute exact path="/map" component={AddUpdateMap} type='Add' />
                                <ProtectedRoute exact path="/map/:id" component={AddUpdateMap} type='Update' />

                                <ProtectedRoute exact path="/product/maps" component={MapProducts} />
                                <ProtectedRoute exact path="/product/map" component={AddUpdateMapProduct} type='Add' />
                                <ProtectedRoute exact path="/product/map/:id" component={AddUpdateMapProduct} type='Update' />

                                <ProtectedRoute exact path="/companies" component={Companies} />
                                <ProtectedRoute exact path="/company" component={AddUpdateCompany} type='Add' />
                                <ProtectedRoute exact path="/company/:id" component={AddUpdateCompany} type='Update' />

                                <ProtectedRoute exact path="/articles" component={Articles} />
                                <ProtectedRoute exact path="/article" component={AddUpdateArticle} type='Add' />
                                <ProtectedRoute exact path="/article/:id" component={AddUpdateArticle} type='Update' />

                                <ProtectedRoute exact path="/magazines" component={Magazines} />
                                <ProtectedRoute exact path="/magazine" component={AddUpdateMagazine} type='Add' />
                                <ProtectedRoute exact path="/magazine/:id" component={AddUpdateMagazine} type='Update' />

                                <ProtectedRoute exact path="/analys" component={Analysis} />
                                <ProtectedRoute exact path="/analysis" component={AddUpdateAnalysis} type='Add' />
                                <ProtectedRoute exact path="/analysis/:id" component={AddUpdateAnalysis} type='Update' />

                                <ProtectedRoute exact path="/presentations" component={Presentation} />
                                <ProtectedRoute exact path="/presentation" component={AddUpdatePresentation} type='Add' />
                                <ProtectedRoute exact path="/presentation/:id" component={AddUpdatePresentation} type='Update' />

                                <ProtectedRoute exact path="/magazine-content" component={MagazinesContent} />
                                <ProtectedRoute exact path="/magazine-content/:id" component={Contents} />
                                <ProtectedRoute exact path="/content" component={AddUpdateContent} type='Add' />
                                <ProtectedRoute exact path="/content/:id" component={AddUpdateContent} type='Update' />

                                <ProtectedRoute exact path="/videos" component={Videos} />
                                <ProtectedRoute exact path="/video" component={AddUpdateVideo} type='Add' />
                                <ProtectedRoute exact path="/video/:id" component={AddUpdateVideo} type='Update' />

                                <ProtectedRoute exact path="/section/videos" component={VideoList} />
                                <ProtectedRoute exact path="/section/video" component={AddUpdateListVideos} type='Add' />
                                <ProtectedRoute exact path="/section/video/:id" component={AddUpdateListVideos} type='Update' />

                                <ProtectedRoute exact path="/countries" component={Country} />
                                <ProtectedRoute exact path="/country" component={AddUpdateCountry} type='Add' />
                                <ProtectedRoute exact path="/country/:id" component={AddUpdateCountry} type='Update' />

                                <ProtectedRoute exact path="/charts" component={Charts} />
                                <ProtectedRoute exact path="/chart" component={AddUpdateCharts} type='Add' />
                                <ProtectedRoute exact path="/chart/:id" component={AddUpdateCharts} type='Update' />

                                <ProtectedRoute exact path="/single/charts" component={Chart} />
                                <ProtectedRoute exact path="/single/chart" component={AddUpdateChart} type='Add' />
                                <ProtectedRoute exact path="/single/chart/:id" component={AddUpdateChart} type='Update' />

                                <ProtectedRoute exact path="/events" component={Events} />

                                <Route exact path="/" render={() => <Redirect to='login' />} />

                                <Route path='*' component={NotFound} />
                            </Switch>
                        </BrowserRouter>
                    </IntlProvider>
                </UserProvider>
            );
        }
    }
}

export default App;
