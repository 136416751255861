import React, { Component } from "react";
import { Button, Form, Input, Layout, Row, Col, message } from "antd";
import Sidebar from "../Components/Sidebar";
import Axios from "../axios";
import { UserOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import styles from "./CSS Modules/Login.module.css";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";

const { Content } = Layout;

class AddUpdateListVideos extends Component {
    intl = this.props.intl;

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            id: null,
            name: "",
            notExist: false,
            isAdded: false,
            isUpdated: false,
            numPages: null,
            pageNumber: 1,
        };
    }

    componentDidMount() {
        if (this.props.computedMatch.params.id) this.getData();
    }

    getData = () => {
        let state = this.props.location.state;
        if (typeof state !== "undefined") {
            this.setState({
                id: state.id,
                name: state.name,
            });
        } else {
            Axios.get("/section/video/" + this.props.computedMatch.params.id)
                .then((result) => {
                    let state = result.data;
                    this.setState({
                        id: state.id,
                        name: state.name,
                    });
                })
                .catch(() => {
                    this.setState({
                        notExist: true,
                    });
                });
        }

        // setTimeout(() => console.log(this.state), 2000);
    };

    createFormData = () => {
        let formData = new FormData();
        formData.append("name", this.state.name);
        return formData;
    };

    onAddClick = (event) => {
        event.target.disabled = true;
        Axios.get("/admin/section/video/", {
            params: {
                name: this.state.name,
            }
        })
            .then((res) => {
                this.setState({
                    isAdded: true,
                });
            })
            .catch((err) => {
                if (err.response.status === 406)
                    message.error(
                        this.intl.formatMessage({ id: "magazine.add.error.already-exist" })
                    );
                else
                    message.error(this.intl.formatMessage({ id: "magazine.add.error" }));
                event.target.disabled = false;
            });
    };

    onUpdateClick = () => {
        Axios.get("/admin/section/update-video", {
            params: {
                id: this.state.id,
                name: this.state.name,
            }
        })
            .then((res) => {
                this.setState({
                    isUpdated: true,
                });
            })
            .catch((err) => {
                message.error(this.intl.formatMessage({ id: "magazine.update.error" }));
            });
    };

    onNameChange = (event) => {
        this.setState({
            name: event.target.value,
        });
    };
    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    };

    render() {
        return (
            <Layout>
                <Sidebar selected={"section-videos"} />
                <Layout
                    className={styles["site-layout"]}
                    style={{ marginLeft: "13vw" }}
                >
                    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
                        <Form
                            name="form_name"
                            fields={[
                                {
                                    name: ["name"],
                                    value: this.state.name,
                                },
                            ]}
                        >
                            <Form.Item>
                                {this.state.type === "Add" ? (
                                    <h1 style={{ textAlign: "center" }}>
                                        {this.intl.formatMessage({ id: "video.add" })}
                                    </h1>
                                ) : (
                                    <h1 style={{ textAlign: "center" }}>
                                        {this.intl.formatMessage({ id: "video.update" })}
                                    </h1>
                                )}
                            </Form.Item>
                            <Row>
                                <Col span={12} offset={6}>
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({
                                                    id: "video.warning.name",
                                                }),
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={
                                                <UserOutlined
                                                    className={styles["site-form-item-icon"]}
                                                />
                                            }
                                            placeholder={this.intl.formatMessage({
                                                id: "video.placeholder.name",
                                            })}
                                            value={this.state.name}
                                            onChange={this.onNameChange}
                                        />
                                    </Form.Item>
                                    <Form.Item>
                                        {this.state.type === "Add" ? (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                onClick={this.onAddClick}
                                                className={styles["login-form-button"]}
                                            >
                                                {this.intl.formatMessage({ id: "video.add" })}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                onClick={this.onUpdateClick}
                                                className={styles["login-form-button"]}
                                            >
                                                {this.intl.formatMessage({ id: "video.update" })}
                                            </Button>
                                        )}
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Form>
                    </Content>
                </Layout>
                {this.state.notExist && <Redirect to="/404" />}
                {this.state.isAdded || this.state.isUpdated ? (
                    <Redirect to="/section/videos" />
                ) : null}
            </Layout>
        );
    }
}

export default injectIntl(AddUpdateListVideos);
