import React, { Component } from "react";
import { Button, Card, Col, Row, message } from "antd";
import { Link } from 'react-router-dom'


import 'antd/dist/antd.css'
import Axios from "../axios";
import { injectIntl } from "react-intl";

message.config({
    top: 0,
    duration: 0.5,
});

class ContentsMagazineCards extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            name: this.props.item.name,
            page: this.props.item.page,
            image: this.props.item.image,
            magazine_id: this.props.item.magazine_id,
            html: this.props.item.html,
        }
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-content/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'content.delete.success' }))
                this.setState({
                    visibility: 0
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'content.delete.error' }))
            })
    }



    showTime = (time) => {
        let date = new Date(time);
        return date.toLocaleString()
    }

    render() {
        return (
            <Col span={24}>
                <Card >
                    <Link to={{
                        pathname: '/content/' + this.state.id,
                        state: {
                            id: this.state.id,
                            name: this.state.name,
                            html: this.state.html,
                            image: this.state.image,
                            magazine_id: this.state.magazine_id,
                            page: this.state.page
                        }
                    }}>
                        <Row >
                            <Col span={20}>
                                {this.state.name}
                            </Col>
                            <Col span={4}>
                                <Button type="danger" block onClick={this.onDeleteClick}>
                                    {this.intl.formatMessage({ id: 'article.delete' })}
                                </Button>
                            </Col>
                        </Row>
                    </Link>
                </Card>

            </Col>
        )
    }
}

export default injectIntl(ContentsMagazineCards)