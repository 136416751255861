import {Component} from "react";
import {Form, Input, Button, message} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';

import 'antd/dist/antd.css'
import styles from "./CSS Modules/Login.module.css"
import UserContext from "../Context/UserContext";
import Axios from "../axios";
import {Link, Redirect} from "react-router-dom";


class ResetPassword extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            code: '',
            pass_changed: false,
            code_send: false,
            password: '',
            confirm_password: ''
        }
    }

    componentDidMount() {
        if (this.props.type === 'Reset') {
            if (this.props.match.params.code) {
                this.setState({
                    code: this.props.match.params.code
                })
            }
        }
    }

    onPasswordChangeClick = (event) => {
        if (this.state.password === this.state.confirm_password && this.state.password.length > 0) {
            event.target.disabled = true

            let params = new URLSearchParams();
            params.append('password', this.state.password)
            params.append('confirm_password', this.state.confirm_password)

            Axios.post('/reset-password/' + this.state.code, params)
                .then((res) => {
                    event.target.disabled = false
                    this.setState({
                        pass_changed: true
                    })
                    message.success('Password changed')
                })
                .catch((err) => {
                    event.target.disabled = false
                    if (err.response.status === 400) message.error('User not exist')
                    else if (err.response.status === 403) message.error('Link is already expired')
                    else message.error('Internal server error')
                })
        }
        else message.error('Password and confirm password should be equal')
    }

    onGetClick = (event) => {
        event.target.disabled = true

        let params = new URLSearchParams();
        params.append('email', this.state.email)

        Axios.post('/reset-password', params)
            .then((res) => {
                event.target.disabled = false
                this.setState({
                    code_send: true
                })
                message.success('Check your email')
            })
            .catch((err) => {
                event.target.disabled = false
                if (err.response.status === 400) {
                    message.error('User not exist')
                } else message.error('Internal server error')
            })
    }

    onResetPasswordClick = () => {
        this.setState({
            reset_password: true
        })
    }

    onEmailChange = (event) => {
        this.setState({
            email: event.target.value
        })
    }

    onPasswordChange = (event) => {
        this.setState({
            password: event.target.value
        })
    }

    onConfirmPasswordChange = (event) => {
        this.setState({
            confirm_password: event.target.value
        })
    }

    render() {
        return (
            <Form
                name="normal_login"
                className={styles['login-form']}
                onFinish={this.onFinish}
            >
                <Form.Item>
                    <h1 style={{textAlign: "center"}}>Reset Password</h1>
                </Form.Item>

                {
                    this.props.type === 'Get' ?
                        <>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Input prefix={<UserOutlined className={styles['site-form-item-icon']}/>}
                                       placeholder="Email"
                                       value={this.state.email}
                                       onChange={this.onEmailChange}
                                       onPressEnter={this.onGetClick}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button onClick={this.onGetClick} type="primary"
                                        className={styles['login-form-button']}>
                                    Get reset password link
                                </Button>
                            </Form.Item>
                        </>
                        :
                        <>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                        pattern: new RegExp(/.{8,}/)
                                    },
                                ]}
                            >
                                <Input prefix={<LockOutlined className={styles['site-form-item-icon']}/>}
                                       placeholder="Password"
                                       value={this.state.password}
                                       onChange={this.onPasswordChange}
                                       type="password"
                                />
                            </Form.Item>

                            <Form.Item
                                name="confirm_password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please re-input your password!',
                                        pattern: new RegExp(/.{8,}/)
                                    },
                                ]}
                            >
                                <Input prefix={<LockOutlined className={styles['site-form-item-icon']}/>}
                                       placeholder="Confirm password"
                                       value={this.state.confirm_password}
                                       onChange={this.onConfirmPasswordChange}
                                       type="password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" onClick={this.onPasswordChangeClick}
                                        className={styles['login-form-button']}>
                                    Change password
                                </Button>
                            </Form.Item>
                        </>
                }
                <Form.Item style={{textAlign: 'center'}}>
                    <Button>
                        <Link to='/login'>
                            Return to Login page
                        </Link>

                    </Button>
                </Form.Item>
                {
                    this.state.pass_changed ? <Redirect to='/login'/> : null
                }
                {
                    this.state.code_send ? <Redirect to='/login'/> : null
                }
            </Form>
        )
    }

}

export default ResetPassword