import React, { Component } from "react";
import { Button, Layout, Row } from "antd";
import Sidebar from "../Components/Sidebar";
import styles from "./CSS Modules/Tags.module.css";
import Axios from "../axios";

import { injectIntl } from "react-intl";
import { Excel } from 'antd-table-saveas-excel';
import { Table } from 'antd';
const { Content } = Layout

class AnorEvents extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            find_member: '',
            page: 1,
            limit: 18,
            count: null,
            items: [],
            isLoaded: false
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        Axios.get('/anor/events', {
            params: {
                limit: this.state.limit,
                page: 1
            }
        })
            .then((res) => {
                console.log(res.data.items)
                this.setState({
                    count: res.data.count,
                    items: res.data.items,
                    isLoaded: true
                })
            })
            .catch(() => {
                this.setState({
                    count: null,
                    page: 1,
                    items: [],
                    isLoaded: true
                })
            })
    }

    columns = [
        {
            title: 'Fullname',
            dataIndex: 'fullname',
        },
        {
            title: 'Address',
            dataIndex: 'address',
        },
        {
            title: 'Companyname',
            dataIndex: 'companyname',
        },
        {
            title: 'isanor',
            dataIndex: 'isanor',
        },
        {
            title: 'anorvolume',
            dataIndex: 'anorvolume',
        },
        {
            title: 'isfarmer',
            dataIndex: 'isfarmer',
        },
        {
            title: 'farmervolume',
            dataIndex: 'farmervolume',
        },
        {
            title: 'ispeople',
            dataIndex: 'ispeople',
        },
        {
            title: 'peoplevolume',
            dataIndex: 'peoplevolume',
        },
        {
            title: 'iselleranor',
            dataIndex: 'iselleranor',
        },
        {
            title: 'selleranorvolume',
            dataIndex: 'selleranorvolume',
        },
        {
            title: 'issuplier',
            dataIndex: 'issuplier',
        },
        {
            title: 'supliervolume',
            dataIndex: 'supliervolume',
        },
        {
            title: 'isphd',
            dataIndex: 'isphd',
        },
        {
            title: 'phdvolume',
            dataIndex: 'phdvolume',
        },
        {
            title: 'another',
            dataIndex: 'another',
        },
        {
            title: 'mass_media',
            dataIndex: 'mass_media',
        },
        {
            title: 'social_media',
            dataIndex: 'social_media',
        },
        {
            title: 'bygoverment',
            dataIndex: 'bygoverment',
        },
        {
            title: 'byfestival',
            dataIndex: 'byfestival',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
        },
        {
            title: 'Telegramphone',
            dataIndex: 'telegramphone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },

    ];

    onFindButtonClick = () => {
        this.findMembers(1)
    }

    onFindMemberNameChange = (event) => {
        this.setState({
            find_member: event.target.value
        })
    }

    render() {
        return (
            <Layout>
                <Sidebar selected="anor-events" />
                <Layout className={styles["site-layout"]} style={{ marginLeft: '13vw' }}>
                    <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                        <div className={styles["site-layout-background"]} style={{ padding: 24 }}>
                            <Row>
                                <div style={{ padding: 24 }}>
                                    <Button
                                        style={{
                                            marginBottom: 20,
                                        }}
                                        onClick={() => {
                                            const excel = new Excel();
                                            excel
                                                .addSheet('Festival users')
                                                .addColumns(this.columns)
                                                .addDataSource(this.state.items)
                                                .saveAs('festival.xlsx');
                                        }}
                                    >
                                        Export excel
                                    </Button>
                                    <Table columns={this.columns} dataSource={this.state.items} style={{ maxWidth: "1200px" }} scroll={{ x: 400 }} className="your-table" />
                                </div>
                            </Row>

                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default injectIntl(AnorEvents)