import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';
import UserContext from "../Context/UserContext";

const ProtectedRoute = ({component: Component, user, ...rest}) => {
    const context = useContext(UserContext)

    return <Route {...rest} render={
        props => {
            if (context.value.isAuthed) {
                return <Component {...rest} {...props} />
            } else {
                return <Redirect to={
                    {
                        pathname: '/login',
                        state: {
                            from: props.location
                        }
                    }
                }/>
            }
        }
    }/>
}

export default ProtectedRoute;