import React, { Component } from "react";
import { Button, Form, Input, Layout, Row, Col, message, Upload } from "antd";
import Sidebar from "../Components/Sidebar";
import Axios from "../axios";
import { UserOutlined, ContactsOutlined, BookOutlined, FileImageOutlined } from "@ant-design/icons";
import 'antd/dist/antd.css'
import styles from "./CSS Modules/Login.module.css"
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";

const { Dragger } = Upload;
const { Content } = Layout

class AddUpdateAnorMember extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            imageURL: '',
            image: null,
            id: null,
            name: '',
            field_of_activity: '',
            contacts: '',
            notExist: false,
            isAdded: false,
            isUpdated: false
        }
    }

    componentDidMount() {
        if (this.props.computedMatch.params.id)
            this.getData()
    }

    getData = () => {
        let state = this.props.location.state


        if (typeof state !== 'undefined') {
            this.setState({
                id: state.id,
                name: state.name,
                field_of_activity: state.field_of_activity,
                contacts: state.contacts,
                imageURL: state.image
            })
        }
        else {
            Axios.get('/anor/member/' + this.props.computedMatch.params.id)
                .then((result) => {
                    let state = result.data
                    this.setState({
                        id: state.id,
                        name: state.name,
                        field_of_activity: state.field_of_activity,
                        contacts: state.contacts,
                        imageURL: state.image
                    })
                })
                .catch(() => {
                    this.setState({
                        notExist: true
                    })
                })
        }

        // setTimeout(() => console.log(this.state), 2000)
    }

    createFormData = () => {
        let formData = new FormData();
        formData.append('image', this.state.image)
        formData.append('name', this.state.name)
        formData.append('field_of_activity', this.state.field_of_activity)
        formData.append('contacts', this.state.contacts)

        return formData
    }

    onAddClick = (event) => {
        event.target.disabled = true
        let formData = this.createFormData()
        Axios.post('/admin/anor/member', formData)
            .then((res) => {
                this.setState({
                    isAdded: true
                })
            })
            .catch((err) => {
                if (err.response.status === 406)
                    message.error(this.intl.formatMessage({ id: 'member.add.error.already-exist' }))
                else message.error(this.intl.formatMessage({ id: 'member.add.error' }))

                event.target.disabled = false
            })
    }

    onUpdateClick = () => {
        let formData = this.createFormData()

        Axios.post('/admin/anor/update-member/' + this.state.id, formData)
            .then((res) => {
                this.setState({
                    isUpdated: true
                })
            })
            .catch((err) => {
                message.error(this.intl.formatMessage({ id: 'member.update.error' }))
            })
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    onFieldChange = (event) => {

        this.setState({
            field_of_activity: event.target.value
        })

    }

    onContactsChange = (event) => {
        this.setState({
            contacts: event.target.value
        })

    }

    onImageChange = (event) => {
        this.setState({
            imageURL: URL.createObjectURL(event.target.files[0]),
            image: event.target.files[0]
        })
    }

    render() {
        return (
            <Layout>
                <Sidebar selected={'anormembers'} />
                <Layout className={styles["site-layout"]} style={{ marginLeft: '13vw' }}>
                    <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                        <Form
                            name="form_name"
                            fields={[
                                {
                                    name: ["name"],
                                    value: this.state.name
                                },
                                {
                                    name: ["field_of_activity"],
                                    value: this.state.field_of_activity
                                },
                                {
                                    name: ["contacts"],
                                    value: this.state.contacts
                                },
                            ]}
                        >
                            <Form.Item>
                                {
                                    this.state.type === 'Add' ?
                                        <h1 style={{ textAlign: "center" }}>
                                            {this.intl.formatMessage({ id: 'member.add' })}
                                        </h1> :
                                        <h1 style={{ textAlign: "center" }}>
                                            {this.intl.formatMessage({ id: 'member.update' })}
                                        </h1>
                                }
                            </Form.Item>
                            <Row>
                                <Col span={11}>
                                    <Form.Item>
                                        <img src={this.state.imageURL} style={{ width: '90%' }}
                                            alt="" />
                                        <Dragger {...{
                                            name: 'image',
                                            multiple: false,
                                            maxCount: 1,
                                            showUploadList: false
                                        }} beforeUpload={(file) => {
                                            if (file.type !== 'image/png' || file.type !== 'image/jpeg' || file.type !== 'image/jpg') {
                                                this.setState({
                                                    imageURL: window.URL.createObjectURL(file),
                                                    image: file
                                                })
                                                return false
                                            } else {
                                                message.error(file.name + ' ' + this.intl.formatMessage({ id: 'article.file-error.image' }));
                                                return false
                                            }
                                        }}
                                            style={{ width: '90%', marginTop: '20px' }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <FileImageOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                {this.intl.formatMessage({ id: 'article.drag.preview-image' })}
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Col>
                                <Col span={13}>
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'member.warning.name' })
                                            },
                                        ]}
                                    >
                                        <Input prefix={<UserOutlined className={styles['site-form-item-icon']} />}
                                            placeholder={this.intl.formatMessage({ id: 'member.placeholder.name' })}
                                            value={this.state.name} onChange={this.onNameChange} />
                                    </Form.Item>
                                    <Form.Item
                                        name="field_of_activity"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'member.warning.activity' })
                                            },
                                        ]}
                                    >
                                        <Input prefix={<BookOutlined className={styles['site-form-item-icon']} />}
                                            placeholder={this.intl.formatMessage({ id: 'member.placeholder.activity' })}
                                            value={this.state.field_of_activity} onChange={this.onFieldChange} />
                                    </Form.Item>
                                    <Form.Item
                                        name="contacts"
                                        rules={[
                                            {
                                                // eslint-disable-next-line
                                                pattern: new RegExp(/[\+]{1}(998)[\ ][0-9]{2}[\ ][0-9]{7}/),
                                                message: this.intl.formatMessage({ id: 'member.warning.contact' })
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<ContactsOutlined
                                                className={styles['site-form-item-icon']} />}
                                            placeholder={this.intl.formatMessage({ id: 'member.placeholder.contact' })}
                                            value={this.state.contacts}
                                            onChange={this.onContactsChange}
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        {
                                            this.state.type === 'Add' ?
                                                <Button type="primary" htmlType="submit" onClick={this.onAddClick}
                                                    className={styles['login-form-button']}>
                                                    {this.intl.formatMessage({ id: 'member.add' })}
                                                </Button>
                                                :
                                                <Button type="primary" htmlType="submit" onClick={this.onUpdateClick}
                                                    className={styles['login-form-button']}>
                                                    {this.intl.formatMessage({ id: 'member.update' })}
                                                </Button>
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Content>
                </Layout>
                {
                    this.state.notExist && <Redirect to='/404' />
                }
                {
                    this.state.isAdded || this.state.isUpdated ? <Redirect to='/members' /> : null
                }
            </Layout>
        )
    }
}

export default injectIntl(AddUpdateAnorMember)