import React, { Component } from "react";
import { Col, Card, Button, message } from "antd";
import Axios from "../axios";
import { Link } from 'react-router-dom'
import { injectIntl } from "react-intl";

const { Meta } = Card;
class PresentationCard extends Component {
    intl = this.props.intl;
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            name: this.props.item.name,
            author: this.props.item.author,
            language: this.props.item.language,
            image: this.props.item.image,
            file: this.props.item.file,
            pptx: this.props.item.pptx,
            numPages: null,
            pageNumber: 1
        }
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    onDeleteClick = () => {
        console.log(this.state.id)
        Axios.post('/admin/presentation/delete-presentation/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'presentation.delete.success' }))
                this.props.onDelete()
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'presentation.delete.error' }))
            })
    }

    handle = () => {
        window.open(this.state.file, '_blanker')
    }
    onDocumentLoad = ({ numPages }) => {
        this.setState({ numPages });
    }
    onError(e) {
        // console.log(e)
    }

    render() {
        return (
            <Col>
                <Card>
                    <Link to={{
                        pathname: '/presentation/' + this.state.id,
                        state: {
                            id: this.state.id,
                            name: this.state.name,
                            author: this.state.author,
                            language: this.state.language,
                            file: this.state.file,
                            image: this.props.item.image,
                            pptx: this.props.item.pptx,
                        }
                    }}>
                        <Card
                            hoverable
                            style={{ width: 240, height: '90%' }}
                        >
                            <Meta title={this.state.name} />
                            <Meta title={this.state.author} />
                        </Card>
                    </Link>

                    <Button style={{ width: 120 }} onClick={this.handle} type='primary' block>
                        {this.intl.formatMessage({ id: 'presentation.download' })}
                    </Button>
                    <Button style={{ width: 120 }} onClick={this.onDeleteClick} type='danger' block>
                        {this.intl.formatMessage({ id: 'presentation.delete' })}
                    </Button>
                </Card>

            </Col>
        )
    }
}

export default injectIntl(PresentationCard)