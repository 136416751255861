import {Component} from "react";
import Sidebar from "../Components/Sidebar";

import 'antd/dist/antd.css';
import styles from './CSS Modules/Tags.module.css'

import {TagsOutlined, Loading3QuartersOutlined} from '@ant-design/icons';

import {Layout, Card, Input, Button, Row, message, Pagination, Form} from "antd";
import TagsCard from "../Components/TagsCard";
import Axios from "../axios";
import {injectIntl} from "react-intl";

const {Content} = Layout

class Tags extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            lang: this.intl.locale,
            add_tag_value: '',
            find_tag_value: '',
            page: 1,
            limit: 20,
            count: null,
            items: [],
            data_not_exist: false,
            isLoaded: false
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        Axios.get('/admin/pagination-tags', {
            params: {
                limit: this.state.limit,
                page: this.state.page
            }
        }).then(res => {
            this.setState({
                count: res.data.count,
                items: res.data.items,
                isLoaded: true
            })
        })
            .catch(() => {
                this.setState({
                    page: 1,
                    items: [],
                    isLoaded: true
                })
            })
    }

    onTagNameChange = (event) => {
        this.setState({
            add_tag_value: event.target.value
        })
    }

    onFindTagNameChange = (event) => {
        this.setState({
            find_tag_value: event.target.value
        })
    }

    onPageChange = (page) => {
        this.setState({
            page: page
        })
        if (this.state.find_tag_value === '') {
            Axios.get('/admin/pagination-tags', {
                params: {
                    limit: this.state.limit,
                    page: page
                }
            })
                .then(res => {
                    this.setState({
                        count: res.data.count,
                        items: res.data.items
                    })
                })
                .catch(() => {
                    this.setState({
                        count: null,
                        page: 1,
                        items: []
                    })
                })
                .finally(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                })
        } else {
            this.findTags(page)
        }
    }

    findTags = (page) => {
        Axios.get('/admin/filtered-tags', {
            params: {
                name: this.state.find_tag_value,
                limit: this.state.limit,
                page: page
            }
        })
            .then((res) => this.setState({
                page: page,
                items: res.data.items,
                count: res.data.count
            }))
            .catch(() => this.setState({page: 1, items: [], count: null}))
            .finally(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            })
    }

    onAddButtonClick = (event) => {
        event.preventDefault()

        if (this.state.add_tag_value.length > 0) {
            event.target.disabled = true

            let params = new URLSearchParams();
            params.append('name', this.state.add_tag_value)

            Axios.post('/admin/tag', params)
                .then(() => {
                    message.success(this.intl.formatMessage({id: "tags.add.success"}))
                    event.target.disabled = false
                    this.getData()
                })
                .catch((err) => {
                    if (err.response.status === 406) {
                        message.error(this.intl.formatMessage({id: "tags.add.error.already-exist"}))
                    } else message.error(this.intl.formatMessage({id: "tags.add.error"}))

                    event.target.disabled = false
                })
        } else {
            message.error(this.intl.formatMessage({id: "tags.add.error.empty-name"}))
        }
    }

    onFindButtonClick = () => {
        this.findTags(1)
    }

    render() {
        return (
            <Layout>
                <Sidebar selected={'tags'}/>
                <Layout className={styles["site-layout"]} style={{marginLeft: '13vw'}}>
                    <Content style={{margin: '24px 16px 0', overflow: 'initial'}}>
                        <div className={styles["site-layout-background"]} style={{padding: 24}}>
                            <Row>
                                <Card title={this.intl.formatMessage({id: "tags.add"})} bordered={true}
                                      style={{width: 300}}>
                                    <Form>
                                        <Form.Item
                                            name="add_tag"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: this.intl.formatMessage({id: "tags.warning"})
                                                },
                                            ]}
                                        >
                                            <Input value={this.state.add_tag_value} onChange={this.onTagNameChange}
                                                   onPressEnter={this.onAddButtonClick}
                                                   placeholder={this.intl.formatMessage({id: "tags.placeholder"})}
                                                   prefix={<TagsOutlined/>}/>
                                        </Form.Item>
                                        <Button onClick={this.onAddButtonClick} htmlType='submit' type="primary" block>
                                            {this.intl.formatMessage({id: "tags.add"})}
                                        </Button>
                                    </Form>
                                </Card>

                                <Card title={this.intl.formatMessage({id: "tags.find"})} bordered={true}
                                      style={{width: 300}}>
                                    <Form>
                                        <Form.Item
                                            name="update_tag"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: this.intl.formatMessage({id: "tags.warning"})
                                                },
                                            ]}
                                        >
                                            <Input value={this.state.find_tag_value} onChange={this.onFindTagNameChange}
                                                   onPressEnter={this.onFindButtonClick}
                                                   placeholder={this.intl.formatMessage({id: "tags.placeholder"})}
                                                   prefix={<TagsOutlined/>}/>
                                        </Form.Item>
                                        <Button onClick={this.onFindButtonClick} htmlType='submit' type="primary" block>
                                            {this.intl.formatMessage({id: "tags.find"})}
                                        </Button>
                                    </Form>
                                </Card>
                            </Row>

                            <hr style={{margin: '30px 0'}}/>

                            <div className={styles["site-card-wrapper"]}>
                                <Row gutter={[8, 8]}>
                                    {
                                        this.state.isLoaded ?
                                            this.state.items.length !== 0 ? this.state.items.map(item => <TagsCard
                                                    key={item.id} item={item}/>) :
                                                <h1>{this.intl.formatMessage({id: 'no-data'})}</h1>
                                            :
                                            <Loading3QuartersOutlined style={{fontSize: '50px', margin: '0 auto'}}
                                                                      spin/>
                                    }
                                </Row>
                            </div>

                            <hr style={{margin: '30px 0'}}/>

                            <Pagination simple style={{marginBottom: '40px', textAlign: 'center'}}
                                        onChange={this.onPageChange}
                                        current={this.state.page}
                                        pageSize={this.state.limit} total={this.state.count}/>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default injectIntl(Tags)