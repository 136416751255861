import React, { Component } from 'react';
import { Button, Col, Form, Input, Layout, message, Select, Upload } from "antd";
import Sidebar from "../Components/Sidebar";
import styles from "./CSS Modules/Tags.module.css";

import { FileImageOutlined, FileTextOutlined } from '@ant-design/icons'
import Axios from "../axios";
import { injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import PDF from "react-pdf-js-infinite";


const { Content } = Layout
const { Option } = Select
const { Dragger } = Upload;

class AddUpdateContent extends Component {
    intl = this.props.intl
    magazines = []
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            type: this.props.type,
            file: '',
            name: '',
            author: '',
            magazine_id: '',
            magazines: [],
            page: '',
            pageNumber: 1,
            limit: 18,
            image_preview: '',
            image_preview_file: null,
            notExist: false,
            isAdded: false,
            isUpdate: false
        };
    }

    componentDidMount() {
        this.getMagazines()
        this.getData()
    }

    getData = () => {
        if (this.state.type === 'Update') {
            Axios.get('/content/' + this.props.computedMatch.params.id)
                .then((res) => {
                    this.setState({
                        file: res.data.pdf,
                        name: res.data.name,
                        author: res.data.author,
                        magazine_id: this.magazines.find(x => x.id === res.data.magazine_id).id,
                        page: res.data.page,
                        image: res.data.image,
                        image_preview: res.data.image
                    })
                })
                .catch(() => {
                    this.setState({
                        notExist: true
                    })
                })
        }
    }

    getMagazines = () => {
        Axios.get('/magazine', {
            params: {
                limit: this.state.limit,
                page: this.state.pageNumber
            }
        })
            .then(res => {
                this.setState({
                    magazines: res.data.items
                })
                this.magazines = res.data.items;
            })
    }


    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    }
    onAuhorChange = (event) => {
        this.setState({
            author: event.target.value
        })
    }



    onPageChange = (event) => {
        this.setState({
            page: event.target.value
        })
    }

    onMagazineChange = (value) => {
        this.setState({
            magazine_id: value
        })
    }

    formData = () => {
        let formData = new FormData()
        formData.append('file', this.state.file)
        formData.append('name', this.state.name)
        formData.append('magazine_id', this.state.magazine_id)
        formData.append('page', this.state.page)
        formData.append('image', this.state.image)

        return formData
    }

    onAddClick = (event) => {
        event.target.disabled = true
        let formData = this.formData();
        Axios.post('/admin/content', formData)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'content.add.success' }))
                this.setState({
                    isAdded: true
                })
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'content.add.error' }))
                event.target.disabled = false
            })
    }

    onUpdateClick = () => {

        let formData = this.formData()
        Axios.post('/admin/update-content/' + this.props.computedMatch.params.id, formData)
            .then(() => {
                this.isUpdate = true;
                message.success(this.intl.formatMessage({ id: 'content.update.success' }))
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'content.update.error' }))
            })
    }

    render() {
        const isFile = this.state.file;
        return (
            <Layout>
                <Sidebar selected={'magazine-content'} />
                <Layout className={styles["site-layout"]} style={{ marginLeft: '13vw' }}>
                    <Content style={{ margin: '0px 16px 0', overflow: 'initial' }} >
                        <div style={{ padding: "50px" }}>
                            <Form
                                style={{ marginBottom: '50px' }}
                                fields={[
                                    {
                                        name: ["name"],
                                        value: this.state.name
                                    },

                                    {
                                        name: ["magazine_id"],
                                        value: this.state.magazine_id
                                    },

                                    {
                                        name: ["page"],
                                        value: this.state.page
                                    },
                                ]}
                            >
                                <Form.Item>
                                    {
                                        this.state.type === 'Add' ?
                                            <h1 style={{ textAlign: "center", fontSize: '30px' }}>
                                                {this.intl.formatMessage({ id: 'content.add' })}
                                            </h1> :
                                            <h1 style={{ textAlign: "center", fontSize: '30px' }}>
                                                {this.intl.formatMessage({ id: 'content.update' })}
                                            </h1>
                                    }
                                </Form.Item>
                                <Col span={8} >
                                    <h1>{this.intl.formatMessage({ id: 'content.input.magazine' })}</h1>
                                    <Form.Item
                                        name="magazine_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'content.warning.magazine' }),
                                            },
                                        ]}
                                    >
                                        <Select
                                            mode="single"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder={this.intl.formatMessage({ id: 'content.placeholder.magazine' })}
                                            onChange={this.onMagazineChange}
                                            value={this.state.magazine_id}
                                        >
                                            {
                                                this.state.magazines.map((magazine, index) => {
                                                    return <Option key={magazine.id} >{magazine.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <h3>{this.intl.formatMessage({ id: 'content.input.name' })}</h3>
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'content.warning.name' }),
                                            },
                                        ]}
                                    >
                                        <Input value={this.state.name} onChange={this.onNameChange}
                                            placeholder={this.intl.formatMessage({ id: 'content.placeholder.name' })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <h3>{this.intl.formatMessage({ id: 'content.input.page' })}</h3>
                                    <Form.Item
                                        name="page"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'content.warning.page' }),
                                            },
                                        ]}
                                    >
                                        <Input value={this.state.author} type="number" onChange={this.onPageChange}
                                            placeholder={this.intl.formatMessage({ id: 'content.placeholder.page' })}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8} style={{ marginTop: '20px' }}>
                                    {
                                        this.state.image_preview === '' ?
                                            <h3 style={{
                                                padding: '10px',
                                                textAlign: 'center',
                                                border: '1px solid black',
                                                width: '100%'
                                            }}>
                                                {this.intl.formatMessage({ id: 'content.placeholder.preview-image' })}
                                            </h3>
                                            :
                                            <img src={this.state.image_preview} width='100%' alt="" />
                                    }
                                </Col>

                                <Col span={8} style={{ marginTop: '20px' }}>
                                    <Form.Item name="preview_image">
                                        <Dragger {...{
                                            name: 'image',
                                            multiple: false,
                                            maxCount: 1,
                                            showUploadList: false
                                        }} beforeUpload={(file) => {
                                            if (file.type !== 'image/png' || file.type !== 'image/jpeg' || file.type !== 'image/jpg') {
                                                Resizer.imageFileResizer(
                                                    file,
                                                    1024,
                                                    1024,
                                                    file.type.slice(6),
                                                    100,
                                                    0,
                                                    (file) => {
                                                        this.setState({
                                                            image_preview: window.URL.createObjectURL(file),
                                                            image: file
                                                        })
                                                    },
                                                    'file',
                                                    200,
                                                    200
                                                );
                                                return false
                                            } else {
                                                message.error(file.name + ' ' + this.intl.formatMessage({ id: 'article.file-error.image' }));
                                                return false
                                            }
                                        }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <FileImageOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                {this.intl.formatMessage({ id: 'content.drag.preview-image' })}
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Col>

                                <Col span={8} style={{ marginTop: '20px' }}>
                                    <Form.Item>
                                        <Dragger
                                            {...{
                                                name: "file",
                                                multiple: false,
                                                maxCount: 1,
                                                showUploadList: false,
                                            }}
                                            beforeUpload={(file) => {
                                                if (file.type !== "pdf") {
                                                    this.setState({
                                                        file: file,
                                                    });
                                                    return false;
                                                } else {
                                                    message.error(
                                                        file.name +
                                                        " " +
                                                        this.intl.formatMessage({
                                                            id: "article.file-error.image",
                                                        })
                                                    );
                                                    return false;
                                                }
                                            }}
                                            style={{ marginTop: "20px" }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <FileTextOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                {this.intl.formatMessage({
                                                    id: "magazine.drag.preview-pdf",
                                                })}
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                    <div style={{ maxHeight: "400px", overflow: "scroll" }}>
                                        {isFile ? (
                                            <PDF file={this.state.file} scale={1.5} />
                                        ) : (
                                            <p>File don't have</p>
                                        )}
                                    </div>
                                </Col>
                                <Col span={8} style={{ marginTop: '20px' }}>
                                    {
                                        this.state.type === 'Add' ?
                                            <Button style={{ marginTop: '50px' }} block type="primary" htmlType="submit"
                                                onClick={this.onAddClick}
                                                className={styles['login-form-button']}>
                                                {this.intl.formatMessage({ id: 'article.add' })}
                                            </Button>
                                            :
                                            <Button style={{ marginTop: '50px' }} block type="primary" htmlType="submit"
                                                onClick={this.onUpdateClick}
                                                className={styles['login-form-button']}>
                                                {this.intl.formatMessage({ id: 'content.update' })}
                                            </Button>
                                    }
                                </Col>
                            </Form>
                        </div>
                    </Content>
                    {
                        this.state.isAdded ? <Redirect to='/magazine-content' /> : null
                    }
                    {
                        this.state.isUpdate ? <Redirect to='/magazine-content' /> : null
                    }
                </Layout>
            </Layout>
        );
    }
}

export default injectIntl(AddUpdateContent)