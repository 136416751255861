import React, { Component } from "react";
import {  Button, Form, Input, Layout, Row, Col, message,   Upload } from "antd";
import Sidebar from "../Components/Sidebar";
import "antd/dist/antd.css";
import styles from "./CSS Modules/Company.model.css";
import {
    FileTextOutlined, 
    PhoneOutlined, AlignLeftOutlined, 
    FileImageOutlined
} from "@ant-design/icons";
import Axios from "../axios";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
const { Content } = Layout
const { Dragger } = Upload;
class AddUpdateCompany extends Component {
    intl = this.props.intl
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            id: null,
            name: '',
            imageURL: '',
            image: null,
            eksport: '',
            address: '',
            mail_web: '',
            image_preview: null,
            phone_number: '',
            notExist: false
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        if (this.state.type === 'Update')
            Axios.get('/admin/company/' + this.props.computedMatch.params.id)
                .then((result) => {
                    let state = result.data
                    this.setState({
                        id: state.id,
                        name: state.name,
                        imageURL: state.image,
                        eksport: state.eksport,
                        address: state.address,
                        mail_web: state.mail_web,
                        region: state.region,
                        phone_number: state.phone_number,
                        isAdded: false
                    })
                })
                .catch(() => {
                    this.setState({
                        notExist: true
                    })
                })
    }

    onAddClick = (event) => {
        event.target.disabled = true
        let params = this.createForm()
        Axios.post('/admin/company', params)
            .then(() => {
                message.error(this.intl.formatMessage({ id: 'company.add.success' }))
                this.setState({
                    isAdded: true
                })
            })
            .catch((err) => {
                if (err.response.status === 406)
                    message.error(this.intl.formatMessage({ id: 'member.add.error.already-exist' }))
                else message.error(this.intl.formatMessage({ id: 'company.add.error' }))

                event.target.disabled = false
            })

    }

    onUpdateClick = () => {
        let params = this.createForm()
        Axios.post('/admin/update-company/' + this.state.id, params)
            .then((res) => {
                this.setState({
                    isAdded: true,
                });
                message.success(this.intl.formatMessage({ id: 'company.update.success' }))
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'company.update.error' }))
            })

    }


    createForm = () => {
        let formData = new FormData();
        formData.append('name', this.state.name)
        formData.append('image', this.state.image)
        formData.append('eksport', this.state.eksport)
        formData.append('address', this.state.address)
        formData.append('mail_web', this.state.mail_web)
        formData.append('phone_number', this.state.phone_number)
        return formData
    }

    onNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    onPhoneNumberChange = (event) => {
        this.setState({
            phone_number: event.target.value
        })
    }

    onAddressChange = (event) => {
        this.setState({
            address: event.target.value
        })
    }

    onMailChange = (event) => {
        this.setState({
            mail_web: event.target.value
        })
    }


    onEksportChange = (event) => {
        this.setState({
            eksport: event.target.value
        })
    }


    render() {
        return (
            <Layout>
                <Sidebar selected={'companies'} />
                <Layout className={styles["site-layout"]} style={{ marginLeft: '28vw' }}>
                    <Content style={{ margin: '24px 16px 0', overflow: 'initial', width: '1000px' }}>
                        <Form
                            name="form_name"
                            fields={[
                                {
                                    name: ["name"],
                                    value: this.state.name
                                },
                                {
                                    name: ["eksport"],
                                    value: this.state.eksport
                                },
                                {
                                    name: ["address"],
                                    value: this.state.address
                                },
                                {
                                    name: ["mail_web"],
                                    value: this.state.mail_web
                                },
                                {
                                    name: ["location"],
                                    value: this.state.location
                                },
                                {
                                    name: ["contact_person"],
                                    value: this.state.contact_person
                                },
                                {
                                    name: ["phone_number"],
                                    value: this.state.phone_number
                                },

                            ]}
                        >
                            <Form.Item>
                                {
                                    this.state.type === 'Add' ?
                                        <h1 style={{ textAlign: "center" }}>
                                            {this.intl.formatMessage({ id: 'company.add' })}
                                        </h1> :
                                        <h1 style={{ textAlign: "center" }}>
                                            {this.intl.formatMessage({ id: 'company.update' })}
                                        </h1>
                                }
                            </Form.Item>
                            <Col span={12} style={{ marginTop: '20px' }}>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: this.intl.formatMessage({ id: 'company.warning.name' })
                                        },
                                    ]}
                                >
                                    <Input prefix={<FileTextOutlined className={styles['site-form-item-icon']} />}
                                        onChange={this.onNameChange}
                                        placeholder={this.intl.formatMessage({ id: 'company.placeholder.name' })}
                                    />
                                </Form.Item>
                            </Col>
                            <Row>
                                <Col span={12} style={{ marginTop: '20px' }}>
                                    <Form.Item name="preview_image">
                                        <Dragger {...{
                                            name: 'image',
                                            multiple: false,
                                            maxCount: 1,
                                            showUploadList: false
                                        }} beforeUpload={(file) => {
                                            if (file.type !== 'image/png' || file.type !== 'image/jpeg' || file.type !== 'image/jpg') {
                                                this.setState({
                                                    image_preview: window.URL.createObjectURL(file),
                                                    image: file
                                                })
                                                return false
                                            } else {
                                                message.error(file.name + ' ' + this.intl.formatMessage({ id: 'article.file-error.image' }));
                                                return false
                                            }
                                        }}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <FileImageOutlined />
                                            </p>
                                            <p className="ant-upload-text">
                                                {this.intl.formatMessage({ id: 'article.drag.preview-image' })}
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Col span={12} style={{ marginTop: '20px' }}>
                                {
                                    this.state.image_preview === '' ?
                                        <h3 style={{
                                            padding: '10px',
                                            textAlign: 'center',
                                            border: '1px solid black',
                                            width: '100%'
                                        }}>
                                            {this.intl.formatMessage({ id: 'article.placeholder.preview-image' })}
                                        </h3>
                                        :
                                        <img src={this.state.image_preview} width='100%' alt="" />
                                }
                            </Col>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        name="eksport"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'company.warning.eksport' }),
                                            },
                                        ]}
                                    >
                                        <Input.TextArea
                                            onChange={this.onEksportChange} value={this.state.eksport}  placeholder={this.intl.formatMessage({ id: 'company.placeholder.eksport' })}
                                        />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    rules={[
                                        {
                                            required: true,
                                            message: this.intl.formatMessage({ id: 'company.warning.address' }),
                                        },
                                    ]}
                                >
                                    <Input.TextArea prefix={<AlignLeftOutlined className={styles['site-form-item-icon']} />}
                                        onChange={this.onAddressChange}
                                        placeholder={this.intl.formatMessage({ id: 'company.placeholder.address' })}
                                    />
                                </Form.Item>
                            </Col>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        name="mail_web"
                                        rules={[
                                            {
                                                required: true,
                                                message: this.intl.formatMessage({ id: 'company.warning.mail' }),
                                            },
                                        ]}
                                    >
                                        <Input.TextArea prefix={<AlignLeftOutlined className={styles['site-form-item-icon']} />}
                                            onChange={this.onMailChange}
                                            placeholder={this.intl.formatMessage({ id: 'company.placeholder.mail' })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                   
                            <Col span={12}>
                                <Form.Item
                                    name="phone_number"
                                    rules={[
                                        {
                                            required: true,
                                            // eslint-disable-next-line
                                            pattern: new RegExp(/[0-9]{2}[\ ][0-9]{7}/),
                                            message: this.intl.formatMessage({ id: 'company.warning.phone-number' }),
                                        },
                                    ]}
                                >
                                    <Input prefix={<PhoneOutlined className={styles['site-form-item-icon']} />}
                                        onChange={this.onPhoneNumberChange}
                                        placeholder={this.intl.formatMessage({ id: 'company.placeholder.phone-number' })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    {
                                        this.state.type === 'Add' ?
                                            <Button block type="primary" htmlType="submit" onClick={this.onAddClick}
                                                className={styles['login-form-button']}>
                                                {this.intl.formatMessage({ id: 'company.add' })}
                                            </Button>
                                            :
                                            <Button block type="primary" htmlType="submit" onClick={this.onUpdateClick}
                                                className={styles['login-form-button']}>
                                                {this.intl.formatMessage({ id: 'company.update' })}
                                            </Button>
                                    }
                                </Form.Item>
                            </Col>

                        </Form>
                    </Content>
                </Layout>
                {
                    this.state.isAdded ? <Redirect to='/companies' /> : null
                }
                {
                    this.state.notExist && <Redirect to='/404' />
                }
            </Layout>
        )
    }
}

export default injectIntl(AddUpdateCompany)