import React, { Component } from "react";
import { Button, Card, Col, message } from "antd";

import { Link } from 'react-router-dom'
import Axios from "../axios";
import { injectIntl } from "react-intl";

class CropsCard extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.item.id,
            region: this.props.item.region,
            name: this.props.item.name,
            sort: this.props.item.sort,
            weight: this.props.item.weight,
        }
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-crops/' + this.state.id)
            .then(() => {
                this.setState({
                    visibility: 0
                })
                message.success(this.intl.formatMessage({ id: 'company.delete.success' }))
                this.props.onDelete()
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'company.delete.error' }))
            })
    }



    render() {
        return (
            <Col span={6}>
                <Card>
                    <Link to={'/crop/' + this.state.id}>
                        <Card style={{ height: "200px" }} title={this.state.name} >
                            <p>{this.state.region}</p>
                            <p>{this.state.sort}</p>
                        </Card>
                    </Link>
                    <Button type="danger" block onClick={this.onDeleteClick}>
                        {this.intl.formatMessage({ id: 'company.delete' })}
                    </Button>
                </Card>

            </Col>
        )
    }
}

export default injectIntl(CropsCard)