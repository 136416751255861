import React, { Component } from "react";
import Sidebar from "../Components/Sidebar";
import { Button, Card, Input, Layout, Pagination, Row, Col } from "antd";
import styles from "./CSS Modules/Tags.module.css";
import { FormOutlined, Loading3QuartersOutlined, UserAddOutlined } from "@ant-design/icons";

import Axios from "../axios";
import { Link } from "react-router-dom";
import CountryCard from "../Components/CountryCard";
import { injectIntl } from "react-intl";

const { Content } = Layout

class Country extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            find_company: '',
            find_products: [],
            page: 1,
            limit: 20,
            count: null,
            items: [],
            activity_types: [],
            isLoaded: false
        }
    }

    componentDidMount() {
        this.getData()
    }



    getData = () => {
        Axios.get('/country', {
            params: {
                limit: this.state.limit,
                page: this.state.page
            }
        })
            .then((res) => {
                this.setState({
                    count: res.data.count,
                    items: res.data.items,
                    isLoaded: true
                })

            })
            .catch(() => {
                this.setState({
                    count: null,
                    items: []
                })
            })
    }

    onPageChange = (page) => {
        this.setState({
            page: page
        })
        if (this.state.find_company === '' && this.state.find_products.length === 0) {
            Axios.get('/country', {
                params: {
                    limit: this.state.limit,
                    page: page
                }
            })
                .then(res => {
                    this.setState({
                        count: res.data.count,
                        items: res.data.items
                    })
                })
                .catch(() => {
                    // this.setState({
                    //     page: 1,
                    //     items: []
                    // })
                })
                .finally(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                })
        } else {
            this.findCompanies(page)
        }
    }

    findCompanies = (page) => {
        Axios.get('/filtered-country', {
            params: {
                name: this.state.find_company,
                products: this.state.find_products,
                limit: this.state.limit,
                page: page
            }
        })
            .then((res) => {
                this.setState({
                    page: page,
                    count: res.data.count,
                    items: res.data.items
                })
            })
            .catch((err) => {
                this.setState({
                    page: 1,
                    count: null,
                    items: []
                })
            })
            .finally(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            })
    }

    onFindCompanyNameChange = (event) => {
        this.setState({
            find_company: event.target.value
        })
    }

    onFindButtonClick = () => {
        this.findCompanies(1)
    }

    onActivityTypeChange = (value) => {
        this.setState({
            find_activity_type: value
        })
    }

    onProductsChange = (value) => {
        this.setState({
            find_products: value
        })
    }

    render() {
        return (
            <Layout>
                <Sidebar selected={'countries'} />
                <Layout className={styles["site-layout"]} style={{ marginLeft: '13vw' }}>
                    <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                        <div className={styles["site-layout-background"]} style={{ padding: 24 }}>
                            <Row>
                                <Card bordered={true}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 300
                                    }}>
                                    <Button style={{ padding: '40px 40px 70px 40px', fontSize: '20px' }}
                                        type="primary" block>
                                        <Link to='/country'>
                                            <UserAddOutlined />{this.intl.formatMessage({ id: 'country.add' })}
                                        </Link>
                                    </Button>
                                </Card>

                                <Card title={this.intl.formatMessage({ id: 'country.find' })} bordered={true}
                                    style={{ width: 300 }}>
                                    <Row style={{ marginTop: '0', marginBottom: '10px' }} gutter={[8, 8]}>
                                        <Col span={24}>
                                            <h4>{this.intl.formatMessage({ id: 'country.input.name' })}</h4>
                                            <Input value={this.state.find_company}
                                                onChange={this.onFindCompanyNameChange}
                                                onPressEnter={this.onFindButtonClick}
                                                placeholder={this.intl.formatMessage({ id: 'country.placeholder.name' })}
                                                prefix={<FormOutlined />} />
                                        </Col>
                                    </Row>
                                    <Button onClick={this.onFindButtonClick} type="primary" block>
                                        {this.intl.formatMessage({ id: 'country.find' })}
                                    </Button>
                                </Card>
                            </Row>

                            <div className={styles["site-card-wrapper"]}>
                                <Row gutter={[8, 8]}>
                                    {
                                        this.state.isLoaded ?
                                            this.state.items.length !== 0 ? this.state.items.map(item => {
                                                return <CountryCard key={item.id} item={item} />
                                            })
                                                :
                                                <h1>{this.intl.formatMessage({ id: 'no-data' })}</h1>
                                            :
                                            <Loading3QuartersOutlined style={{ fontSize: '50px', margin: '0 auto' }}
                                                spin />
                                    }
                                </Row>
                            </div>

                            <hr style={{ margin: '30px 0' }} />

                            <Pagination simple style={{ marginBottom: '40px', textAlign: 'center' }}
                                onChange={this.onPageChange}
                                current={this.state.page}
                                pageSize={this.state.limit} total={this.state.count} />
                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default injectIntl(Country)