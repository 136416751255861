import React, { Component } from "react";
import { Col, Card, Button, message } from "antd";
import Axios from "../axios";

import { Link } from 'react-router-dom'
import { injectIntl } from "react-intl";

const { Meta } = Card;

class MemberCard extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            name: this.props.item.name,
            field_of_activity: this.props.item.field_of_activity,
            contacts: this.props.item.contacts,
            image: this.props.item.image
        }
    }

    onDeleteClick = () => {
        Axios.post('/admin/delete-member/' + this.state.id)
            .then(() => {
                message.success(this.intl.formatMessage({ id: 'member.delete.success' }))
                this.props.onDelete()
            })
            .catch(() => {
                message.error(this.intl.formatMessage({ id: 'member.delete.error' }))
            })
    }

    render() {
        return (
            <Col >
                <Card >
                    <Link to={{
                        pathname: '/member/' + this.state.id,
                        state: {
                            id: this.state.id,
                            name: this.state.name,
                            field_of_activity: this.state.field_of_activity,
                            contacts: this.state.contacts,
                            image: this.state.image,
                        }
                    }}>
                        <Card
                            hoverable
                            style={{ width: 240, height: '90%' }}
                            cover={<img style={{ height: '200px', objectFit: 'contain' }} alt={this.state.name} src={this.state.image} />}
                        >
                            <Meta title={this.state.name} description={this.state.field_of_activity} />
                            <Meta description={this.state.contacts} />
                        </Card>
                    </Link>
                    <Button style={{ width: 240 }} onClick={this.onDeleteClick} type='danger' block>
                        {this.intl.formatMessage({ id: 'member.delete' })}
                    </Button>
                </Card>

            </Col>
        )
    }
}

export default injectIntl(MemberCard)