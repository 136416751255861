import React, { Component } from "react";
import moment from "moment";
import {
  DatePicker,
  Button,
  Form,
  Input,
  Layout,
  Row,
  Col,
  message,
  Select,
  Tooltip,
} from "antd";
import Sidebar from "../Components/Sidebar";
import "antd/dist/antd.css";
import styles from "./CSS Modules/Company.model.css";

import {
  CloseOutlined
} from "@ant-design/icons";
import Axios from "../axios";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import ColorPicker from "rc-color-picker";
import "rc-color-picker/assets/index.css";
const { Content } = Layout;

const { Option } = Select;

class AddUpdateCharts extends Component {
  intl = this.props.intl;
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      id: null,
      product: "",
      product_from: "",
      product_group: "",
      product_sell_type: "",
      volume: "",
      color: "",
      visibility: null,
      prices: [{
        local_price: "",
        local_price_discount: "",
        price_dollar: "",
        date: "",
      }],
      notExist: false,
      countries: [],
      country_market: "",
      symbols: [],
      country_money: "",
      local_price: "",
      local_price_discount: "",
      price_dollar: "",
      date: ""
    };
  }

  componentDidMount() {
    this.getCountry();
    this.getSymbol();
    this.getData();
  }

  getCountry = () => {
    Axios.get("/country/name")
      .then((result) => {
        this.setState({
          countries: result.data,
        });
      })
      .catch(() => {
        this.setState({
          notExist: true,
        });
      });
  };

  getSymbol = () => {
    Axios.get("/country/symbol")
      .then((result) => {
        this.setState({
          symbols: result.data,
        });
      })
      .catch(() => {
        this.setState({
          notExist: true,
        });
      });
  };

  getData = () => {
    if (this.state.type === "Update")
      Axios.get("/admin/charts/" + this.props.computedMatch.params.id)
        .then((result) => {
          let state = result.data;
          this.setState({
            id: state.id,
            country_market: state.country_market,
            country_money: state.country_money,
            product: state.product,
            product_from: state.product_from,
            product_group: state.product_group,
            product_sell_type: state.product_sell_type,
            volume: state.volume,
            color: state.color,
            visibility: state.visibility,
            prices: state.prices == null ? [] : JSON.parse(state.prices),
            isAdded: false,
          });
        })
        .catch(() => {
          this.setState({
            notExist: true,
          });
        });
  };

  onAddClick = (event) => {
    let productIsNull = false;
    this.state.prices.forEach((x) => {
      if (x.date === "") {
        productIsNull = true;
      }
    });
    if (!productIsNull) {
      event.target.disabled = true;

      let params = this.createForm();
      Axios.post("/admin/charts", params)
        .then(() => {
          message.error(this.intl.formatMessage({ id: "company.add.success" }));
          this.setState({
            isAdded: true,
          });
        })
        .catch((err) => {
          if (err.response.status === 406)
            message.error(
              this.intl.formatMessage({ id: "member.add.error.already-exist" })
            );
          else
            message.error(this.intl.formatMessage({ id: "company.add.error" }));

          event.target.disabled = false;
        });
    } else {
      message.error(this.intl.formatMessage({ id: "company.products.error" }));
    }
  };

  onUpdateClick = () => {
    let productIsNull = false;
    this.state.prices.forEach((x) => {
      if (x.date === "") {
        productIsNull = true;
      }
    });

    if (!productIsNull) {
      let params = this.createFormUpdate();
      Axios.post("/admin/update-charts/" + this.state.id, params)
        .then((res) => {
          this.setState({
            isAdded: true,
          });
          message.success(
            this.intl.formatMessage({ id: "company.update.success" })
          );
        })
        .catch(() => {
          message.error(
            this.intl.formatMessage({ id: "company.update.error" })
          );
        });
    } else {
      message.error(this.intl.formatMessage({ id: "company.products.error" }));
    }
  };


  createForm = () => {
    let params = new URLSearchParams();
    params.append("country_market", this.state.country_market);
    params.append("country_money", this.state.country_money);
    params.append("product", this.state.product);
    params.append("product_from", this.state.product_from);
    params.append("product_group", this.state.product_group);
    params.append("product_sell_type", this.state.product_sell_type);
    params.append("volume", this.state.volume);
    params.append("color", this.state.color);
    params.append("prices", JSON.stringify(this.state.prices));
    return params;
  };

  createFormUpdate = () => {
    let params = new URLSearchParams();
    params.append("country_market", this.state.country_market);
    params.append("country_money", this.state.country_money);
    params.append("product", this.state.product);
    params.append("product_from", this.state.product_from);
    params.append("product_group", this.state.product_group);
    params.append("product_sell_type", this.state.product_sell_type);
    params.append("volume", this.state.volume);
    params.append("color", this.state.color);
    params.append("prices", JSON.stringify(this.state.prices));
    return params;
  };
  onPricesLocalChange = (event, index) => {
    let prices = this.state.prices;
    prices[index].local_price = event.target.value;
    this.setState({
      prices: prices,
    });
  };

  onPricesLocalDiscountChange = (event, index) => {
    let prices = this.state.prices;
    prices[index].local_price_discount = event.target.value;
    this.setState({
      prices: prices,
    });

  };

  onPricesLocalDollarChange = (event, index) => {
    let prices = this.state.prices;
    prices[index].price_dollar = event.target.value;
    this.setState({
      prices: prices,
    });
  };

  onPricesLocalDateChange = (event, index) => {
    let prices = this.state.prices;
    prices[index].date = new Date(event._d);
    this.setState({
      prices: prices,
    });
  };

  onDeleteClick = (event, index) => {
    let prices = this.state.prices;
    prices.splice(index, 1);
    this.setState({
      prices: prices,
    });
  };

  onProductClick = () => {
    this.setState({
      prices: [...this.state.prices, {
        local_price: "",
        local_price_discount: "",
        price_dollar: "",
        date: ""
      }],
    });
  };

  onNameChange = (event) => {
    this.setState({
      name: event.target.value,
    });
  };


  onProducChange = (event) => {
    this.setState({
      product: event.target.value,
    });
  };

  onProductFromChange = (event) => {
    this.setState({
      product_from: event.target.value,
    });
  };

  onVolumeChange = (event) => {
    this.setState({
      volume: event.target.value,
    });
  };



  onProductGroupChange = (event) => {
    this.setState({
      product_group: event.target.value,
    });
  };

  onDateChange = (event) => {
    this.setState({
      date: event._d,
    });
  };

  onChange(date, dateString) {
    this.setState({
      open: date._d,
    });
  }

  handleCountry = (value) => {
    this.setState({ country_market: value });
  };

  handleSymbol = (value) => {
    this.setState({ country_money: value });
  };

  changeHandler = (colors) => {
    this.setState({
      color: colors.color
    })
  };



  closeHandler = (colors) => {
    // console.log(colors);
  };

  handleChange = (value) => {
    this.setState({
      product_sell_type: value
    })
  };
  getDateFormat = date => {
    var d = moment(date);
    return date && d.isValid() ? d : '';
  };
  render() {
    const options = this.state.countries.map((d) => (
      <Option key={d.name}>{d.name}</Option>
    ));
    const optionSymbol = this.state.symbols.map((d) => (
      <Option key={d.currency_symbol}>{d.currency_symbol}</Option>
    ));
    return (
      <Layout>
        <Sidebar selected={"companies"} />
        <Layout
          className={styles["site-layout"]}
          style={{ marginLeft: "28vw" }}
        >
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
              width: "1000px",
            }}
          >
            <Form
              name="form_name"
              fields={[
                {
                  name: ["country_market"],
                  value: this.state.country_market,
                },
                {
                  name: ["country_money"],
                  value: this.state.country_money,
                },
                {
                  name: ["product"],
                  value: this.state.product,
                },
                {
                  name: ["product_from"],
                  value: this.state.product_from,
                },
                {
                  name: ["product_group"],
                  value: this.state.product_group,
                },
                {
                  name: ["producr_sell_type"],
                  value: this.state.producr_sell_type,
                },
                {
                  name: ["volume"],
                  value: this.state.volume,
                },

                {
                  name: ["color"],
                  value: this.state.color,
                },
              ]}
            >
              <Form.Item>
                {this.state.type === "Add" ? (
                  <h1 style={{ textAlign: "center" }}>
                    {this.intl.formatMessage({ id: "company.add" })}
                  </h1>
                ) : (
                  <h1 style={{ textAlign: "center" }}>
                    {this.intl.formatMessage({ id: "company.update" })}
                  </h1>
                )}
              </Form.Item>
              <Row style={{ marginBottom: "20px" }}>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.country_market}
                    defaultActiveFirstOption={false}
                    onChange={this.handleCountry}
                    notFoundContent={null}

                  >
                    {options}
                  </Select>
                </Col>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.country_money}
                    defaultActiveFirstOption={false}
                    onChange={this.handleSymbol}
                    notFoundContent={null}
                  >
                    {optionSymbol}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="product"
                    rules={[
                      {
                        required: true,
                      },
                    ]} >
                    <Input
                      onChange={this.onProducChange}
                      placeholder={this.intl.formatMessage({ id: 'chart.placeholder.product' })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="product_from"
                    rules={[{ required: true }]} >
                    <Input
                      onChange={this.onProductFromChange}
                      placeholder={this.intl.formatMessage({ id: 'chart.placeholder.product_from' })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="product_group"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      onChange={this.onProductGroupChange}
                      placeholder={this.intl.formatMessage({ id: 'chart.placeholder.product_group' })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    defaultValue="Qadoqlangan"
                    value={this.state.product_sell_type}
                    onChange={this.handleChange}
                  >
                    <Option value="Qadoqlangan">Qadoqlangan</Option>
                    <Option value="Vazn">Vazn</Option>
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="volume"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input type="number"
                      onChange={this.onVolumeChange}
                      placeholder={this.intl.formatMessage({ id: 'chart.placeholder.volume' })}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <ColorPicker
                    color={this.state.color}
                    alpha={30}
                    onChange={this.changeHandler}
                    onClose={this.closeHandler}
                    placement="topLeft"
                    className="some-class"
                    enableAlpha={false}
                  >
                    <button className="rc-color-picker-trigger"></button>
                  </ColorPicker>
                </Col>
              </Row>
              <h1>
                {this.intl.formatMessage({ id: "company.input.products" })}
              </h1>
              <Form.Item>
                {this.state.prices.map((prices, index) => {
                  return (
                    <>
                      <Form.Item>
                        <input
                          type="number"
                          value={prices.local_price}
                          style={{ marginRight: "10px" }}
                          placeholder={this.intl.formatMessage({ id: 'chart.placeholder.local_price' })}
                          onChange={(event) => {
                            this.onPricesLocalChange(event, index);
                          }}
                        />

                        <input
                          type="number"
                          value={prices.local_price_discount}
                          style={{ marginRight: "10px" }}
                          placeholder={this.intl.formatMessage({ id: 'chart.placeholder.local_price_discount' })}

                          onChange={(event) => {
                            this.onPricesLocalDiscountChange(event, index);
                          }}
                        />

                        <input
                          type="number"
                          value={prices.price_dollar}
                          style={{ marginRight: "10px" }}
                          placeholder={this.intl.formatMessage({ id: 'chart.placeholder.price_dollar' })}
                          onChange={(event) => {
                            this.onPricesLocalDollarChange(event, index);
                          }}
                        />
                        <DatePicker
                          placeholder={this.intl.formatMessage({ id: 'chart.placeholder.product_date' })}
                          format={'DD/MM/YYYY'}
                          value={this.getDateFormat(prices.date)}
                          onChange={
                            (event) => {
                              this.onPricesLocalDateChange(event, index);
                            }
                          }
                        />
                        <Tooltip>
                          <Button
                            shape="circle"
                            icon={<CloseOutlined />}
                            type="danger"
                            onClick={(event) => {
                              this.onDeleteClick(event, index);
                            }}
                          />
                        </Tooltip>
                      </Form.Item>
                    </>
                  );
                })}
                <Button type="primary" onClick={this.onProductClick}>
                  {this.intl.formatMessage({ id: "company.product.add" })}
                </Button>
              </Form.Item>

              <Form.Item>
                {this.state.type === "Add" ? (
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={this.onAddClick}
                    className={styles["login-form-button"]}
                  >
                    {this.intl.formatMessage({ id: "company.add" })}
                  </Button>
                ) : (
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    onClick={this.onUpdateClick}
                    className={styles["login-form-button"]}
                  >
                    {this.intl.formatMessage({ id: "company.update" })}
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Content>
        </Layout>
        {this.state.isAdded ? <Redirect to="/charts" /> : null}
        {this.state.notExist && <Redirect to="/404" />}
      </Layout>
    );
  }
}

export default injectIntl(AddUpdateCharts);
