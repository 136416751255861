import React, { Component } from "react";
import { Button, Card, Input, Layout, Pagination, Row } from "antd";
import Sidebar from "../Components/Sidebar";
import styles from "./CSS Modules/Tags.module.css";
import AnalysisCard from "../Components/AnalysisCard";
import Axios from "../axios";
import { FileTextOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { injectIntl, } from "react-intl";
import { Loading3QuartersOutlined, } from "@ant-design/icons";
const { Content } = Layout

class Analysis extends Component {
    intl = this.props.intl

    constructor(props) {
        super(props);
        this.state = {
            find_member: '',
            page: 1,
            limit: 18,
            count: null,
            items: [],
            isLoaded: false
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        Axios.get('/analys', {
            params: {
                limit: this.state.limit,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    count: res.data.count,
                    items: res.data.items,
                    isLoaded: true
                })
            })
            .catch(() => {
                this.setState({
                    count: null,
                    page: 1,
                    items: [],
                    isLoaded: true
                })
            })
    }

    onPageChange = (page) => {
        this.setState({
            page: page
        })
        if (this.state.find_member === '') {
            Axios.get('/analys', {
                params: {
                    limit: this.state.limit,
                    page: page
                }
            })
                .then(res => {
                    this.setState({
                        count: res.data.count,
                        items: res.data.items
                    })
                })
                .catch(() => {
                    this.setState({
                        page: 1,
                        items: []
                    })
                })
                .finally(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                })
        } else {
            this.findAnalysis(page)
        }
    }

    findAnalysis = (page) => {
        Axios.get('/analysis-filter', {
            params: {
                name: this.state.find_member,
                limit: this.state.limit,
                page: page
            }
        })
            .then((res) => {
                this.setState({
                    page: page,
                    count: res.data.count,
                    items: res.data.items
                })
            })
            .catch(() => {
                this.setState({
                    count: null,
                    page: 1,
                    items: []
                })
            })
            .finally(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            })
    }

    onFindButtonClick = () => {
        this.findAnalysis(1)
    }

    onFindMemberNameChange = (event) => {
        this.setState({
            find_member: event.target.value
        })
    }

    render() {
        return (
            <Layout>
                <Sidebar selected="analysis" />
                <Layout className={styles["site-layout"]} style={{ marginLeft: '13vw' }}>
                    <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                        <div className={styles["site-layout-background"]} style={{ padding: 24 }}>
                            <Row>
                                <Card bordered={true}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 300
                                    }}>
                                    <Button style={{ padding: '40px 40px 70px 40px', fontSize: '20px' }}
                                        onClick={this.onAddButtonClick} type="primary" block>
                                        <Link to='/analysis'>
                                            <FileTextOutlined />{this.intl.formatMessage({ id: "analysis.add" })}
                                        </Link>
                                    </Button>
                                </Card>

                                <Card title={this.intl.formatMessage({ id: "analysis.find" })} bordered={true}
                                    style={{ width: 300 }}>
                                    <Input value={this.state.find_member} onChange={this.onFindMemberNameChange}
                                        onPressEnter={this.onFindButtonClick}
                                        placeholder={this.intl.formatMessage({ id: "analysis.placeholder" })}
                                        prefix={<FileTextOutlined />} />
                                    <Button onClick={this.onFindButtonClick} type="primary" block>
                                        {this.intl.formatMessage({ id: "analysis.find" })}
                                    </Button>
                                </Card>
                            </Row>

                            <div className={styles["site-card-wrapper"]}>
                                <Row type='flex' gutter={[16, 16]}>
                                    {
                                        this.state.isLoaded ?
                                            this.state.items.length !== 0 ? this.state.items.map(item => {
                                                return <AnalysisCard key={item.id} item={item} onDelete={this.getData} />
                                            })
                                                :
                                                <h1>{this.intl.formatMessage({ id: 'no-data' })}</h1>
                                            :
                                            <Loading3QuartersOutlined style={{ fontSize: '50px', margin: '0 auto' }}
                                                spin />
                                    }
                                </Row>
                            </div>

                            <Pagination simple style={{ marginBottom: '40px', textAlign: 'center' }}
                                onChange={this.onPageChange}
                                current={this.state.page}
                                pageSize={this.state.limit} total={this.state.count}
                            />
                        </div>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default injectIntl(Analysis)