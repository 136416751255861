import React, { Component } from "react";
import moment from "moment";
import { DatePicker, Button, Form, Input, Layout, Row, Col, message, Select, Tooltip } from "antd";
import Sidebar from "../Components/Sidebar";
import "antd/dist/antd.css";
import styles from "./CSS Modules/Company.model.css";
import { CloseOutlined } from "@ant-design/icons";
import Axios from "../axios";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
const { Content } = Layout;

const { Option } = Select;

class AddUpdateChart extends Component {
  intl = this.props.intl;
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.type,
      id: null,
      product: "",
      volume: "",
      color: "",
      visibility: null,
      prices: [
        {
          price: "",
          date: "",
        },
      ],
      notExist: false,
      countries: [],
      country: "",
      price: "",
      date: "",
    };
  }

  componentDidMount() {
    this.getCountry();
    this.getData();
  }

  getCountry = () => {
    Axios.get("/country/name")
      .then((result) => {
        this.setState({
          countries: result.data,
        });
      })
      .catch(() => {
        this.setState({
          notExist: true,
        });
      });
  };

  getData = () => {
    if (this.state.type === "Update")
      Axios.get("/admin/chart/" + this.props.computedMatch.params.id)
        .then((result) => {
          let state = result.data;
          this.setState({
            id: state.id,
            country: state.country,
            product: state.product,
            volume: state.volume,
            visibility: state.visibility,
            prices: state.prices == null ? [] : JSON.parse(state.prices),
            isAdded: false,
          });
        })
        .catch(() => {
          this.setState({
            notExist: true,
          });
        });
  };

  onAddClick = (event) => {
    let productIsNull = false;
    this.state.prices.forEach((x) => {
      if (x.date === "") {
        productIsNull = true;
      }
    });
    if (!productIsNull) {
      event.target.disabled = true;

      let params = this.createForm();
      Axios.post("/admin/chart", params)
        .then(() => {
          message.error(this.intl.formatMessage({ id: "company.add.success" }));
          this.setState({
            isAdded: true,
          });
        })
        .catch((err) => {
          if (err.response.status === 406)
            message.error(
              this.intl.formatMessage({ id: "member.add.error.already-exist" })
            );
          else
            message.error(this.intl.formatMessage({ id: "company.add.error" }));

          event.target.disabled = false;
        });
    } else {
      message.error(this.intl.formatMessage({ id: "company.products.error" }));
    }
  };

  onUpdateClick = () => {
    let productIsNull = false;
    this.state.prices.forEach((x) => {
      if (x.date === "") {
        productIsNull = true;
      }
    });

    if (!productIsNull) {
      let params = this.createFormUpdate();
      Axios.post("/admin/update-chart/" + this.state.id, params)
        .then((res) => {
          this.setState({
            isAdded: true,
          });
          message.success(
            this.intl.formatMessage({ id: "company.update.success" })
          );
        })
        .catch(() => {
          message.error(
            this.intl.formatMessage({ id: "company.update.error" })
          );
        });
    } else {
      message.error(this.intl.formatMessage({ id: "company.products.error" }));
    }
  };

  createForm = () => {
    let params = new URLSearchParams();
    params.append("country", this.state.country_market);
    params.append("product", this.state.product);
    params.append("volume", this.state.volume);
    params.append("prices", JSON.stringify(this.state.prices));
    return params;
  };

  createFormUpdate = () => {
    let params = new URLSearchParams();
    params.append("country", this.state.country_market);
    params.append("product", this.state.product);
    params.append("volume", this.state.volume);
    params.append("prices", JSON.stringify(this.state.prices));
    return params;
  };
  onPricesLocalChange = (event, index) => {
    let prices = this.state.prices;
    prices[index].price = event.target.value;
    this.setState({
      prices: prices,
    });
  };

  onPricesLocalDateChange = (event, index) => {
    let prices = this.state.prices;
    prices[index].date = new Date(event._d);
    this.setState({
      prices: prices,
    });
  };

  onDeleteClick = (event, index) => {
    let prices = this.state.prices;
    prices.splice(index, 1);
    this.setState({
      prices: prices,
    });
  };

  onProductClick = () => {
    this.setState({
      prices: [
        ...this.state.prices,
        {
          price: "",
          date: "",
        },
      ],
    });
  };

  onProducChange = (event) => {
    this.setState({
      product: event.target.value,
    });
  };

  onVolumeChange = (event) => {
    this.setState({
      volume: event.target.value,
    });
  };

  onDateChange = (event) => {
    this.setState({
      date: event._d,
    });
  };

  onChange(date, dateString) {
    this.setState({
      open: date._d,
    });
  }

  handleCountry = (value) => {
    this.setState({ country: value });
  };

  getDateFormat = (date) => {
    var d = moment(date);
    return date && d.isValid() ? d : "";
  };
  render() {
    const options = this.state.countries.map((d) => (
      <Option key={d.name}>{d.name}</Option>
    ));

    return (
      <Layout>
        <Sidebar selected={"companies"} />
        <Layout
          className={styles["site-layout"]}
          style={{ marginLeft: "28vw" }}
        >
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
              width: "1000px",
            }}
          >
            <Form
              name="form_name"
              fields={[
                {
                  name: ["country"],
                  value: this.state.country,
                },
                {
                  name: ["product"],
                  value: this.state.product,
                },
                {
                  name: ["volume"],
                  value: this.state.volume,
                },
              ]}
            >
              <Form.Item>
                {this.state.type === "Add" ? (
                  <h1 style={{ textAlign: "center" }}>
                    {this.intl.formatMessage({ id: "company.add" })}
                  </h1>
                ) : (
                  <h1 style={{ textAlign: "center" }}>
                    {this.intl.formatMessage({ id: "company.update" })}
                  </h1>
                )}
              </Form.Item>
              <Row style={{ marginBottom: "20px" }}>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    value={this.state.country}
                    defaultActiveFirstOption={false}
                    onChange={this.handleCountry}
                    notFoundContent={null}
                  >
                    {options}
                  </Select>
                </Col>
              </Row>
              <Col span={12}>
                <Form.Item
                  name="product"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    onChange={this.onProducChange}
                    placeholder={this.intl.formatMessage({
                      id: "chart.placeholder.product",
                    })}
                  />
                </Form.Item>
              </Col>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="volume"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      onChange={this.onVolumeChange}
                      placeholder={this.intl.formatMessage({
                        id: "chart.placeholder.volume",
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <h1>
                {this.intl.formatMessage({ id: "company.input.products" })}
              </h1>
              <Form.Item>
                {this.state.prices.map((prices, index) => {
                  return (
                    <>
                      <Form.Item>
                        <input
                          type="number"
                          value={prices.price}
                          style={{ marginRight: "10px" }}
                          placeholder={this.intl.formatMessage({
                            id: "chart.placeholder.local_price",
                          })}
                          onChange={(event) => {
                            this.onPricesLocalChange(event, index);
                          }}
                        />

                        <DatePicker
                          placeholder={this.intl.formatMessage({
                            id: "chart.placeholder.product_date",
                          })}
                          format={"DD/MM/YYYY"}
                          value={this.getDateFormat(prices.date)}
                          onChange={(event) => {
                            this.onPricesLocalDateChange(event, index);
                          }}
                        />
                        <Tooltip>
                          <Button
                            shape="circle"
                            icon={<CloseOutlined />}
                            type="danger"
                            onClick={(event) => {
                              this.onDeleteClick(event, index);
                            }}
                          />
                        </Tooltip>
                      </Form.Item>
                    </>
                  );
                })}
                <Button type="primary" onClick={this.onProductClick}>
                  {this.intl.formatMessage({ id: "company.product.add" })}
                </Button>
              </Form.Item>
              <Col span={12}>
                <Form.Item>
                  {this.state.type === "Add" ? (
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      onClick={this.onAddClick}
                      className={styles["login-form-button"]}
                    >
                      {this.intl.formatMessage({ id: "company.add" })}
                    </Button>
                  ) : (
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      onClick={this.onUpdateClick}
                      className={styles["login-form-button"]}
                    >
                      {this.intl.formatMessage({ id: "company.update" })}
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Form>
          </Content>
        </Layout>
        {this.state.isAdded ? <Redirect to="/charts" /> : null}
        {this.state.notExist && <Redirect to="/404" />}
      </Layout>
    );
  }
}

export default injectIntl(AddUpdateChart);
